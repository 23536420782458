import React, { Component } from 'react';
import './index.css';
import ReactNumeric from './react-numeric/src/index';

export default class AccountingInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			valid: true
		}

		this._handleKeyPress = this._handleKeyPress.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._handleFocus = this._handleFocus.bind(this);

		this.textInput = null;
		this.setTextInputRef = element => {
			this.textInput = element;
		};

		this.focusTextInput = () => {
			// Focus the text input using the raw DOM API
			if (this.textInput) this.textInput.focus();
		};
	}

	/*	
	componentDidMount() {
		// autofocus the input on mount
		this.focusTextInput();
	}
	*/
	_handleKeyPress(event) {
		if(event.key === 'Enter') {
			console.log('validate here!')
		}
	}

	_handleFocus(event) {
		if(event.target)
			return event.target.select();
	}

	_onBlur() {
		let value = this.props.value;
		if(value.length <= 0)
			return;

		try {
			value = parseFloat(value);

			if(isNaN(value)) {
        // eslint-disable-next-line
				throw {
					name: "JigError/invalid_number",
					message: "An invalid character was detected, please re-check your inputted value!",
					code: 'JigError_01'
				}
			} else {
				this.setState({ 'valid': true })
				return this.props.onChange(value)
			}
		} catch(err) {
			if(err.code === 'JigError_01') //Invalid number
			{
				//alert(err.message)
				this.setState({ 'valid': false })
				/*return setTimeout(function(){
					return this.focusTextInput();
				}.bind(this), 0)*/
			} else {
				alert(err)
				this.setState({ 'valid': false })
				return setTimeout(function(){
					return this.focusTextInput();
				}.bind(this), 0)
			}
		}
	}

	render() {
		return this.props.type === 'dollar' ? (
			<ReactNumeric currencySymbol="$" onChange={(event, value) => { return this.props.onChange(value)} } type="text" tabIndex={this.props.tabIndex} style={this.props.style} value={this.props.value || ''} />
		) : (
			<ReactNumeric onChange={(event, value) => { return this.props.onChange(value)} } type="text" tabIndex={this.props.tabIndex} style={this.props.style} value={this.props.value || ''} />
		)
	}
};
