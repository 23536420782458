import React, { Component } from 'react';
import './index.css';
import { Redirect } from 'react-router-dom'

export class HomeScreen extends Component {
	constructor (props) {
		super(props)
		this.state = {
      jid: null,
      jid_typing: null,
      redirectPage: null
    }
    
    this.handleChange = this.handleChange.bind(this);
    this.validateJID = this.validateJID.bind(this);
  }
  
  handleChange(e) {
    console.log(`home - index - handleChange(${e})`)
    var val = e.target.value;
    this.setState({
      jid_typing: val
    })
  }
  
  validateJID() {
    let JID = this.state.jid_typing;
    console.log(`home - index - validateJID(${JID})`)
    return this.setState({
      'jid': JID
    })
  }

  render() {
    var jobViewRedirectJSX = <Redirect to={`/job/${this.state.jid}`} push />;
    var loginRedirectJSX = <Redirect to={`/login`} push />;
    
    return this.props.currentUser !== null ? (
      <div className={'job-homescreen-container'}>
        <div className={'job-homescreen-form'}>
          <div className="job-homescreen-form-container">
            <p className={'job-homescreen-title'}>Enter a Job Number</p>
            <div className={'job-homescreen-input-container'}>
              <input onKeyPress={event => { if (event.key === 'Enter') { this.validateJID() } }} onChange={e => {this.handleChange(e)}} placeholder="######"/>
              <button onClick={() => this.validateJID() }>Submit</button>
              {this.state.jid !== null ? jobViewRedirectJSX : null}
            </div>
          </div>
        </div>
      </div>
    ) : loginRedirectJSX;
  }
}