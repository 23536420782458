//Calculated totals
import React, { Component, Fragment } from 'react';
import MongoDB from '../../datastore/mongodb';
import JobSummaryHeader from '../subcomponents/job-summary-header/index';
import JobSummaryBody from '../subcomponents/job-summary-body/index';
import JobSummaryFooter from '../subcomponents/job-summary-footer/index';
import { database_value } from '../../datastore/firebase';
import { headers } from '../../constants/tableValues';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment';
import LoadingScreen from '../loading-screen/index';
import ApprovedScreen from '../approved-screen/index';
import ChargedScreen from '../charged-screen/index';
import { DndProvider, TouchTransition } from 'react-dnd-multi-backend';
import TouchBackend from 'react-dnd-touch-backend';
import HTML5Backend from 'react-dnd-html5-backend';
//import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'; // or any other pipeline


const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend
    },
    {
      backend: TouchBackend, // Note that you can call your backends with options
      options: {enableMouseEvents: true},
      preview: true,
      transition: TouchTransition
    }
  ]
};

class JobSummaryApp extends Component {
	constructor (props) {
		super(props)
		this.state = {
      visible: false,
      ready: false,
      mongo: new MongoDB(),
      reportDate: moment(),
      billedHours: [],
      jobRateOverride: [],
      statuses: {
        approved: {
          value: false,
          creator: null,
          show_animation: false
        },
        charged: {
          value: false,
          creator: null,
          amount: 0
        },
      },
      deductions: {
        itemData: [],
        headerData: []
      },
      goodwills: {
        itemData: [],
        headerData: []
      },
      coupons: {
        itemData: [],
        headerData: []
      },
      notes: {
        itemData: [],
        headerData: []
      },
      expenses: {
        itemData: [],
        headerData: []
      },
      labor: {
        itemData: [],
        headerData: []
      }
    }

    this.firebaseHandlers = this.firebaseHandlers.bind(this);
    this.newRow = this.newRow.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.updateReportDate = this.updateReportDate.bind(this);
    this.updateJobData = this.updateJobData.bind(this);
    this.chargeActionable = this.chargeActionable.bind(this);
    this.approveActionable = this.approveActionable.bind(this);

    //Firebase refs
    this.jobRef = props.database.ref('/correction_tool_app/');
    this.jobPresenceRef = props.database.ref(`/correction_tool_app/jobs/${props.jid}/events/presence/`);
    this.jobReportDateRef = props.database.ref(`/correction_tool_app/jobs/${props.jid}/reportDate/`);
    this.jobBilledHoursRef = props.database.ref(`/correction_tool_app/jobs/${props.jid}/billed_hours/`);
    this.jobRateOverrideRef = props.database.ref(`/correction_tool_app/jobs/${props.jid}/job_rate_override/`);
    this.userRef = props.database.ref(`/correction_tool_app/presence/`);
    this.onlineStatusRef = props.database.ref('/.info/connected');
    this.sessionId = this.jobRef.child('sessions').child(`${this.props.firebase_uid}`).push().key;
    this.formDataRef = props.database.ref(`/correction_tool_app/jobs/${props.jid}/formData`);

    //TODO: Listen to these refs and change the UI!
    this.approveRef = props.database.ref(`/correction_tool_app/jobs/${props.jid}/approved/`);
    this.chargeRef = props.database.ref(`/correction_tool_app/jobs/${props.jid}/charged/`);

    this.toggleReady = this.toggleReady.bind(this);
  }

  componentWillUnmount() {
    this.jobBilledHoursRef.off();
    this.approveRef.off();
    this.chargeRef.off();
    this.jobReportDateRef.off();
    this.jobRateOverrideRef.off();
    this.formDataRef.off();
    console.log("[JOBVIEW] - Unmounted!")
    return this.props.updateJID(null);
  }

  firebaseHandlers() {
    this.approveRef.on('value', (snapshot) => {
      var approved = snapshot.val();
      if(approved)
      {
        var statuses = this.state.statuses;
        statuses.approved.creator = approved.creator;
        statuses.approved.value = approved.completed;
        statuses.approved.show_animation = false;
        this.setState({ 'statuses': statuses })
      }
    })

    this.chargeRef.on('value', (snapshot) => {
      var charged = snapshot.val();
      if(charged)
      {
        var statuses = this.state.statuses;
        statuses.charged.creator = charged.creator;
        statuses.charged.value = charged.completed;
        statuses.charged.amount = charged.amount;

        this.setState({ 'statuses': statuses })
      }
    })

    //Online or offline? Let's find out!
    this.onlineStatusRef.on('value', (snapshot) => {
      if (snapshot.val()) {
        this.jobRef.child('sessions').child(`${this.props.firebase_uid}`).child(`${this.sessionId}`).child(`start_time`).set(database_value.ServerValue.TIMESTAMP);
        this.state.mongo.getUserIp().then(ip => {
          this.jobRef.child('sessions').child(`${this.props.firebase_uid}`).child(`${this.sessionId}`).child(`ip_address`).set(ip)
        })

        //On Disconnect
        this.userRef.child(`${this.props.firebase_uid}`).onDisconnect().set(database_value.ServerValue.TIMESTAMP);
        this.jobPresenceRef.child(`${this.props.firebase_uid}`).onDisconnect().remove();
        this.jobRef.child('sessions').child(`${this.props.firebase_uid}`).child(`${this.sessionId}`).child(`end_time`).onDisconnect().set(database_value.ServerValue.TIMESTAMP);

        //On Connect
        this.userRef.child(`${this.props.firebase_uid}`).set(`${this.props.jid}`);
        this.jobPresenceRef.child(`${this.props.firebase_uid}`).set(true);
      }
    })

    //Are we sure you're on the latest version?
    this.props.database.ref('/correction_tool_app/').child('version').on("value", (snapshot) => {
      let currentVersion = this.props.app_version;
      let receivedVersion = snapshot.val();
      console.log("Downloaded", currentVersion)
      console.log("Latest", receivedVersion)

      if(currentVersion < receivedVersion)
      {
        //Instruct the user how to update!
        confirmAlert({
          title: 'Your client is outdated', // Title dialog
          message: "Please press CTRL + F5 or click the 'Confirm' button to refresh.", // Message dialog
          childrenElement: () => <div>Once you confirm this action, the page will refresh.</div>, // Custom UI or Component
          buttons: [
            {
              label: 'Not now',
              onClick: () => window.alert('Please consider the concequences of your actions. Press CTRL + F5 when you are ready...')
            },
            {
              label: 'Confirm',
              onClick: () => window.location.reload(true)
            }
          ],
        })
      } else {
        console.log("User is on latest version, do something here! Uhh maybe?")
      }
    })

    //Tell me, when did we begin attempting to bill for this job?
    //Automated-data entry doesn't lie so I won't ask you when.
    this.jobRef.child('jobs').child(`${this.props.jid}`).child('events').child('creationDate').once("value", (snapshot) => {
      const creationDate = snapshot.val();
      console.log(typeof(creationDate), creationDate, isNaN(creationDate))
      if(creationDate === null || creationDate === undefined || isNaN(creationDate))
      {
        return this.jobRef.child('jobs').child(`${this.props.jid}`).child('events').child('creationDate').set(database_value.ServerValue.TIMESTAMP);
      } else {
        console.log("Loaded previously loaded job!")
        return creationDate;
      }
    })

    //When was this report created?
    this.jobReportDateRef.on("value", (snapshot) => {
      let reportDate = snapshot.val();

      if(reportDate && !isNaN(reportDate)) {
        console.log("Timestamp found", reportDate, moment(reportDate).startOf('day'))
        this.setState({ 'reportDate': moment(reportDate).startOf('day') })
      } else {
        console.log("Timestamp missed, time to make up our own report date for now!")
        this.setState({'reportDate': moment().startOf('day') })
      }
    })

    this.jobRateOverrideRef.on("value", (snapshot) => {
      let jobRateOverride = snapshot.val();
      if(jobRateOverride) {
        let jobRateOverrideKeys = Object.keys( jobRateOverride );
        let jobRateOverrideArray = [];

        for(let i = 0; i < jobRateOverrideKeys.length; i++) {
          let key = jobRateOverrideKeys[i];
          console.log(key, jobRateOverride[key])
          let obj = {
            [key]: jobRateOverride[key]
          }
          jobRateOverrideArray.push(obj);
        }

        this.setState({
          'jobRateOverride': jobRateOverrideArray
        })
        console.log("jobRateOverrideArray", jobRateOverrideArray)
      }
    })

    //What does the hours breakdown look like?
    this.jobBilledHoursRef.on("value", (snapshot) => {
      let billedHours = snapshot.val();
      if(billedHours) {
        let billedHoursKeys = Object.keys( billedHours );
        let billedHoursArray = [];

        for(let i = 0; i < billedHoursKeys.length; i++) {
          let key = billedHoursKeys[i];
          console.log(key, billedHours[key])
          let obj = {
            [key]: billedHours[key]
          }
          billedHoursArray.push(obj);
        }

        this.setState({
          'billedHours': billedHoursArray
        })
        console.log("billedHoursArray", billedHoursArray)
      }
    })

    //Forms? You mean those things you enter data in?
    //Pull them down from the cloud NOW in real-time!
    this.formDataRef.on("value", (snapshot) => {
      let formDataVal = snapshot.val();
      let formData = [];
      let deductions = {
        itemData: formData.deductions || [],
        headerData: headers.deductions || [],
      };

      let goodwills = {
        itemData: formData.goodwills || [],
        headerData: headers.goodwills || [],
      };

      let coupons = {
        itemData: formData.coupons || [],
        headerData: headers.coupons || [],
      };

      let notes = {
        itemData: formData.notes || [],
        headerData: headers.notes || [],
      };

      let expenses = {
        itemData: formData.expenses || [],
        headerData: headers.expenses || [],
      };

			let labor = {
				itemData: formData.labor || [],
        headerData: headers.labor || [],
			}

      if(formDataVal) {
        console.log("Forms?", formDataVal)
        var formDataKeys = Object.keys(formDataVal);
        for(let i = 0; i < formDataKeys.length; i++) {
          let formDataKeyValues = Object.values(formDataVal[formDataKeys[i]])
          for(let j = 0; j < formDataKeyValues.length; j++) {
            console.log(formDataKeys[i], formDataKeyValues[j])
            switch(formDataKeys[i]) {
              case 'deductions':
                deductions.itemData.push(formDataKeyValues[j])
                break;
              case 'goodwills':
                goodwills.itemData.push(formDataKeyValues[j])
                break;
              case 'coupons':
                coupons.itemData.push(formDataKeyValues[j])
                break;
              case 'notes':
                notes.itemData.push(formDataKeyValues[j])
                break;
              case 'expenses':
                expenses.itemData.push(formDataKeyValues[j])
                break;
							case 'labor':
								labor.itemData.push(formDataKeyValues[j])
								break;
              default:
                break;
            }
          }
        }
      }

      this.setState({
        "deductions": deductions,
        "goodwills": goodwills,
        "coupons": coupons,
        "notes": notes,
        "expenses": expenses,
				"labor": labor
      })
    })
  }

  pushRow = ( dataField, data ) =>  {
    console.log("pushRow", dataField , data)
    //dataField = "expenses"
    var newDemo = this.state[[dataField]];
    //var headerData = newDemo.headerData;
    var rowObj = Object.assign({}, data);

    //Update firebase!
    var formDataKey = this.props.database.ref(`/correction_tool_app/jobs/${this.props.jid}/formData/${dataField}`).push().key;

    rowObj.firebaseKey = formDataKey;

    newDemo.itemData.push(rowObj);
    console.log("newRow", newDemo)
    this.setState({
      [dataField]: newDemo
    })
    this.props.database.ref( `/correction_tool_app/jobs/${this.props.jid}/formData/${dataField}/${formDataKey}/` ).update( rowObj )
  }

  findAndRemoveUserImage = ( dataField, fallbackDataField ) => {
    return this.props.database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/${dataField}`).remove()
      .then( (result, error) => {
        console.log("result", result, error)
        return this.props.database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded`).once("value")
          .then( snapshot => {
            return snapshot.forEach( (childSnapshot) => {
              var childVal = childSnapshot.val();
              var childKey = childSnapshot.key;
              console.log("childVal", childVal, childKey)
              if(childVal.name === fallbackDataField) {
                console.log(`TODO: Delete /correction_tool_app/images/${this.props.jid}/user_uploaded/${childKey}`)
                return this.props.database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/${childKey}`).remove()
              }
              return childVal;
            })
          })
      })
  }

  newRow( dataField ) {
    var newDemo = this.state[[dataField]];
    var headerData = newDemo.headerData;
    var rowObj = {};
    console.log(newDemo)
    for(let i = 0; i < headerData.length; i++) {
      rowObj[[headerData[i].field]] = null;

      if(headerData[i].type === 'image')
      {
        rowObj['document'] = {
          id: null,
          name: null,
          src: null,
          source: null
        }
      }
    }

    //Update firebase!
    var formDataKey = this.props.database.ref(`/correction_tool_app/jobs/${this.props.jid}/formData/${dataField}`).push().key;

    rowObj.firebaseKey = formDataKey;

    newDemo.itemData.push(rowObj);
    console.log("newRow", newDemo)
    this.setState({
      [dataField]: newDemo
    })
  }

  deleteRow( dataField, arrayIndx ) {
    var newDemo = this.state[[ dataField ]];
    console.log("REMOVE -> ", newDemo.itemData[arrayIndx].firebaseKey)
    let firebaseKey = newDemo.itemData[arrayIndx].firebaseKey;
    this.props.database.ref(`/correction_tool_app/jobs/${this.props.jid}/formData/${dataField}/${firebaseKey}`).remove().then(() => {
      console.log("Removed row (" + firebaseKey + ") from server-side!")
    }).catch(err => {
      console.log("Failed to remove row! (" + firebaseKey + ")", dataField, arrayIndx, newDemo[arrayIndx])
    })
    newDemo.itemData.splice(arrayIndx, 1);

    this.setState({
      [dataField]: newDemo
    })
  }

  updateRow( dataField, arrayIndx, data) {
    console.log("updateRow()", dataField, arrayIndx, data)
    var isSafe = true;
    try {
      var values = Object.values(data);
      values.map(x => {
        if(x === undefined || x === null) {
          isSafe = false;
        }
        return isSafe;
      })
      if(isSafe === false) {
        throw new Error("JigError/Unknown_Error")
      }
    } catch (e) {
      return null;
    }
    var newDemo = Object.assign({}, this.state[[ dataField ]]);
    var currentRow = newDemo.itemData[arrayIndx];
    var newRow = Object.assign(currentRow, data)

    let currentRef = `/correction_tool_app/jobs/${this.props.jid}/formData/`;
    if(newRow.firebaseKey)
    {
      this.props.database.ref(currentRef).child(dataField).child(newRow.firebaseKey).update(newRow).then((error) => {
        console.log("newRow.firebaseKey = true", error)
      }).catch(err => {
        console.log(`[ERROR] ${currentRef}${newRow.firebaseKey}`, newRow)
      })
    } else {
      console.log("Houston, we have a problem", newRow)
    }

    newDemo.itemData[arrayIndx] = newRow;
    this.setState({
      [dataField]: newDemo
    })
  }

  updateReportDate( newReportDate ) {
    console.log(`updateReportDate( ${newReportDate} )`)
    let newDate = moment(newReportDate).startOf('day').valueOf();
    this.jobReportDateRef.set(newDate).then(() => {
      return this.setState({'reportDate': moment(newDate)})
    }).catch(err => {
      console.log("[FATAL] Cannot updateReportDate", err)
      return this.setState({'reportDate': moment().startOf('day')})
    })
  }

  updateJobData(jid, data) {
    console.log(`updateJobData(${jid}, ${JSON.stringify(data)})`);
    var keys = Object.keys(data);
    switch(keys[0]) {
      case 'billed':
        return this.jobBilledHoursRef.child(`${jid}`).set(data.billed);
      case 'rate':
        return this.jobRateOverrideRef.child(`${jid}`).set(data.rate);
      default:
        //TODO: Catch this case!
        break;
    }
  }

  chargeActionable(amt) {
    var chargedVal = this.state.statuses.charged;
    var finalAmount = amt || 0;
    return this.chargeRef.set({
      completed: !chargedVal.value,
      creator: this.props.firebase_uid,
      date: database_value.ServerValue.TIMESTAMP,
      amount: finalAmount,
      stripe_charge_id: 'ready'
    }).then( () => {
      console.log(`SUCCESSFULLY ${ !chargedVal.value === true ? 'UNDID CHARGE FOR' : 'CHARGED'} $${finalAmount.toFixed(2)} dollars!`)

      var statusArr = this.state.statuses;
      statusArr.charged.value = chargedVal.value;
      statusArr.charged.creator = this.props.firebase_uid;
      statusArr.charged.show_animation = true;
      statusArr.charged.amount = finalAmount;
      return this.setState({ statuses: statusArr })
      //return true;
      //return alert(`SUCCESSFULLY ${ !chargedVal.value === true ? 'UNDID CHARGE FOR' : 'CHARGED'} $${finalAmount.toFixed(2)} dollars!`)
    }).catch( err => {
      return alert("FAILED TO CHARGE! -> " + err.toString())
    })
  }

  approveActionable() {
    var approvedVal = false;
    return this.approveRef.once("value", (snapshot) => {
      return snapshot;
    }).then( approvedValue => {
      var approvedValTmp = approvedValue.val();
      try {
        approvedVal = approvedValTmp.completed;
      } catch (e) {}
      approvedVal = !approvedVal;

      var timestamp = database_value.ServerValue.TIMESTAMP;
      return this.approveRef.set({
        completed: approvedVal,
        creator: this.props.firebase_uid,
        date: timestamp
      }).then( () => {
        console.log(`- SUCCESSFULLY ${ approvedVal === true ? '': 'UN'}APPROVED! -`)
        //TODO: When we approve, we update dashflow too!
        //this.props.database.ref(`/vwork_jobs/5f4edaabf9bac4c16ce65da4/checkboxes/paperwork_recieved`)
        var statusArr = this.state.statuses;
        statusArr.approved.value = approvedVal;
        statusArr.approved.creator = this.props.firebase_uid;
        statusArr.approved.show_animation = true;
        return this.setState({ statuses: statusArr })
        //return alert(`- SUCCESSFULLY ${ approvedVal === false ? '': 'UN'}APPROVED! -`)
      }).catch( err => {
        return alert(`FAILED TO ${ approvedVal === true ? '': 'UN'}APPROVE! -> ` + err.toString())
      })
    }).catch( err => {
      return alert(`FAILED TO ${ approvedVal === true ? '': 'UN'}APPROVE! -> ` + err.toString())
    })
  }

  toggleReady(readyBoolean) {
    console.log("toggleReady(" + readyBoolean + ")")
    if(readyBoolean === undefined || readyBoolean === null)
    {
      console.log("ready | If statement", readyBoolean)
      this.setState({
        'ready': !this.state.ready
      })
    } else {
      console.log("ready | Else statement", readyBoolean)
      this.setState({
        'ready': readyBoolean
      })
    }
  }

  componentDidMount() {
    //Bootstrap firebase here!
	console.log("[jobView] componentDidMount()");
    this.firebaseHandlers();
    return this.props.updateJID(this.props.jid);
  }

  getApprovedValue = () => {
    console.log("[isApproved] - getApprovedValue()", this.state.statuses.approved.value)
    return this.state.statuses.approved.value;
  }

  getChargedValue = () => {
    console.log("[isApproved] - getChargedValue()", this.state.statuses.charged.value)
    return this.state.statuses.charged;
  }

	componentDidUpdate(prevProps, prevState, snapshot) {
			var updateLaborCharges = () => {
					let newState = this.state.labor;
					let laborData = newState.itemData || [];
					console.log('[JobSummaryBody] updateLaborCharges', newState)
					console.log("[JobSummaryBody] this.state.labor", this.state.labor, "prevState.labor =", prevState.labor)
					var headerData = newState.headerData;

					for(let i = 0; i < headerData.length; i++) {
						let data = headerData[i];
						if(data.type === "calculated") {
							var calculation = data.calculation;
							let dataToStore = {[data.field] : 0};
							console.log("[JobSummaryBody] labor", laborData)
							for(let k = 0; k < laborData.length; k++) {
								switch(calculation.action) {
									case 'multiply':
										var curVal = 1;
										//Process calculation
										for(let j = 0; j < calculation.fields.length; j++) {
											console.log(`[JobSummaryBody] laborData[${k}][${calculation.fields[j]}]`, laborData[k][calculation.fields[j]])

											var field = laborData[k][calculation.fields[j]];
											if(field == null) {
												field = 0;
											}

											if(calculation.fields[j] === 'billed') {
												field = moment.duration(field, 'seconds').asHours()
											}
											curVal = parseFloat(curVal * field);
										}
										console.log("[JobSummaryBody] dataToStore[data.field]", `${dataToStore[data.field]}`, `${data.field}`)
										dataToStore[data.field] = curVal;
										break;
									default:
										break;
								}
								var newData = Object.assign(laborData[k], dataToStore);
								laborData[k] = newData;
								this.setState({'labor': newState})
								console.log("[JobSummaryBody] COMPLETE!", newState)
							}
						}
					}
				}

				// eslint-disable-next-line
				if(this.state.labor != prevState.labor)
				{
					console.log("[JobView] labor changed!")
					updateLaborCharges();
					//updateBilledHours();
				}
	}

  render() {
    var arr = [];
    for(let i = 0; i < 3; i++)
      arr.push(i);

    return (
      <Fragment>
      {/* Loader */}
      <LoadingScreen ready={this.state.ready} jid={this.props.jid} />
      {/* If Approved, show approved | If Charged, show charged */}
      {
        this.state.statuses.approved.show_animation === true ? (
          <ApprovedScreen showAnimation={this.state.statuses.approved.show_animation} isApproved={this.getApprovedValue} jid={this.props.jid} />
        ) : null
      }
      {
        this.state.statuses.charged.value === true ? (
          <ChargedScreen showAnimation={this.state.statuses.charged.show_animation} isCharged={this.getChargedValue} jid={this.props.jid} />
        ) : null
      }
      <div className={'job-print'}>
        {/* Header */}
        <JobSummaryHeader {...this.props} mongo={this.state.mongo} reportDate={this.state.reportDate} updateReportDate={this.updateReportDate}/>

        {/* Body */}
        <JobSummaryBody app_version={this.props.app_version} jid={this.props.jid} {...this.props} ready={this.state.ready} toggleReady={this.toggleReady} labor={this.state.labor} deductions={this.state.deductions} goodwills={this.state.goodwills} coupons={this.state.coupons} notes={this.state.notes} expenses={this.state.expenses} arr={arr} mongo={this.state.mongo} firebaseStorage={this.props.storage} newRow={this.newRow} deleteRow={this.deleteRow} findAndRemoveUserImage={this.findAndRemoveUserImage} pushRow={this.pushRow} updateRow={this.updateRow} firebase_uid={this.props.firebase_uid} updateJobData={this.updateJobData} billedHours={this.state.billedHours} jobRateOverride={this.state.jobRateOverride} />

        {/* Footer */}
        <JobSummaryFooter jid={this.props.jid} mongo={this.state.mongo} firebaseDatabase={this.props.database} expenseData={this.state.expenses.itemData} laborData={this.state.labor.itemData} billedHours={this.state.billedHours} jobRateOverride={this.state.jobRateOverride} actionables={{'charge': this.chargeActionable, 'approve': this.approveActionable}} statuses={this.state.statuses} firebaseStorage={this.props.storage} />
      </div>

      </Fragment>
    );
  }
};



//export default DragDropContext( MultiBackend(HTML5toTouch) )(JobSummary);

//export default JobSummary;

const JobSummary = (props) => {
	return (
		<DndProvider options={HTML5toTouch}>
			<JobSummaryApp {...props} />
		</DndProvider>
	);
}

export default JobSummary;
