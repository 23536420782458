import React, { Component } from 'react';
import './index.css';

export default class Badges extends Component {
	constructor (props) {
		super(props)
		this.state = {
			badgeLink : { 
				vWork: `https://go.vworkapp.com/html_client/jobs/${this.props.vwork_jid}/edit`
			}
		};

		this.followLink = this.followLink.bind(this);
	}

	componentDidMount() {
		console.log("[vWorkBadge] Initialized")
	}

	followLink() {
		return window.open(this.state.badgeLink.vWork, "_blank");
	}

	render() {
		var names = this.props.names || [];
		return (
			names.map((nameObj, i) => {
				let badgeName = nameObj.toLowerCase();

				return (
					<div onClick={() => { return this.followLink(badgeName) }} key={`badges-${badgeName}-${i}`} className={`job-badge job-badge-${badgeName}`}>
						{nameObj}
					</div>
				);
			})
		);
	}
};