import React, { Component } from 'react';
import './index.css';
import TblCell from './TblCell/index';
import ImgCell from './ImgCell/index';
import DropdownCell from './DropdownCell/index';
//onEnter -> nextFocus();

/*
//Use plinko to explain software development process ;)

import Viewer from 'react-viewer';

<Viewer
  visible={this.state.visible}
  onClose={() => { this.setState({ visible: false }); } }
  images={[{src: imageData.src, alt: (imageData.name)}]}
/>
*/

//TODO: Data bindings upstream!
export default class FancyTable extends Component {
	constructor (props) {
		super(props)
		this.state = {
      visible: false,
      dropdownVal: null,
			defaultButton: props.customButton != null ? false : true
    }

    this.renderRows = this.renderRows.bind(this);
    this.updateRow = this.updateRow.bind(this);
  }

  updateRow( dataField, arrayIndx, data) {
    console.log("[FANCY Table]", `updateRow( ${dataField}, ${arrayIndx}, ${JSON.stringify(data)})`)
    /*var focusItem = null;
    try {
      focusItem = this.focusOrder[arrayIndx];
    } catch(e) {}

    console.log(this.focusOrder, focusItem)

    if(focusItem) {

      console.log("Show me how to fight for now", focusItem)

      try {
        focusItem.focus()
      } catch(e) {
        console.log("Hmmm [ERROR]", e)
      }
    } else console.log("this.focusOrder", this.focusOrder)
    */
    return this.props.updateRow( dataField, arrayIndx, data)
  }

  renderRows(j, customSortedArray) {
    if(!j)
      j = 0;

    var rowDataDir = customSortedArray[j];
    return this.props.headerData.map((tHeadObj, i) => {
      console.log("renderRows(" + j + ")(" + this.props.name + ") => " + tHeadObj.field + " [" + j + "]")
      var fieldJSX = rowDataDir[[tHeadObj.field]];
      var isImage = (tHeadObj.type === 'image' ? true : false )
      var isDropdown = (tHeadObj.type === 'dropdown' ? true : false )
      var imageData = {
        name: null,
        src: null
      };

      if(isImage)
      {
        if(!fieldJSX)
        {
          fieldJSX = {};
          fieldJSX.name = null;
          fieldJSX.src = null;
        }
        imageData.name = fieldJSX.name || imageData.name;
        imageData.src = fieldJSX.src || imageData.src;
        fieldJSX = (<ImgCell imageData={imageData} updateRow={this.updateRow} name={this.props.name} fieldIndx={j} />);
      } else if(isDropdown)
      {
        var dropdown = tHeadObj.dropdown;
        var options = [];
        try {
          options = this.props.dropdownData[dropdown.options];
        } catch(e) {
          options = [];
        }
        fieldJSX = (<DropdownCell tabIndex={0} editable={tHeadObj.editable} options={options} dropdown={dropdown} updateRow={this.updateRow} name={this.props.name} fieldName={tHeadObj.field} fieldIndx={j} value={rowDataDir[[tHeadObj.field]]} firebaseKey={rowDataDir.firebaseKey} />);
      }

      var finalJSX = null;
      console.log('[JobFancyTable]', rowDataDir, rowDataDir.firebaseKey)
      //Display cell contents?
      if(fieldJSX !== null && (isImage || isDropdown)) {
        finalJSX = (<td key={rowDataDir.firebaseKey !== undefined ? (rowDataDir.firebaseKey + '_' + j + '_' + tHeadObj.field) : 'firebaseKey_' + j +  '_' + tHeadObj.field} style={{'justifyContent': 'center'}}>{fieldJSX}</td>);
      } else {
        console.log('[JobFancyTable]', tHeadObj)
        finalJSX = (
          <td key={rowDataDir.firebaseKey !== undefined ? (rowDataDir.firebaseKey + '_' + j + '_' + tHeadObj.field) : 'firebaseKey_' + j +  '_' + tHeadObj.field} style={{'cursor': 'text'}}>
            <TblCell appendToVision={this.appendToVision} tabIndex={0} name={this.props.name} value={rowDataDir[[tHeadObj.field]]} firebaseKey={rowDataDir.firebaseKey} type={tHeadObj.type === 'calculated' ? tHeadObj.calculation.type : tHeadObj.type} dateFormat={tHeadObj.dateFormat || null} editable={tHeadObj.editable} fieldName={tHeadObj.field} fieldIndx={j} updateRow={this.updateRow} jid={this.props.name === 'techs' ? rowDataDir.id : null} updateJobData={this.props.updateJobData}/>
          </td>
        );
      }

      return finalJSX;
    })
  }

  //TODO: Add a drawer (or something)
  //on each table's empty space
  //for sorting options!
  render() {
    var sortByField = this.props.sortByField || null;
    var customSortedArray = this.props.rowData || [];
    if(sortByField !== null)
    {
      customSortedArray.sort(function(a, b) {
        console.log('[SORT]', a,b)
        if(a[sortByField] > b[sortByField]) {
          return -1;
        } else if (a[sortByField] < b[sortByField]) {
          return 1;
        } else return 0;
      })
    }
    return this.props.headerData !== undefined ? (
      <div style={{'display': 'block'}} className="tbl-print-fix">
         <table className="table-fill">
          <thead>
            <tr>
              {this.props.headerData.map((tHeadObj, i) => {
                return (
                  <th key={`${tHeadObj.name}-${i}`}>{tHeadObj.name}</th>
                );
              })}
              <th className={'no-print'} style={{'display': (this.props.removable === false ? 'none': 'table-cell') }}></th>
            </tr>
          </thead>
          <tbody style={{"borderBottom": "1px solid #aaaaaaaa"}}>
            {customSortedArray.map((rowObj, i) => {
              return (
                <tr key={`${this.props.name}-${i}`}>
                  {this.renderRows(i, customSortedArray)}
                  <td className={'no-print'} style={{'display': (this.props.removable === false ? 'none': 'table-cell'), 'textAlign': 'center'}}>
                    <div className={'input-action-buttons'} style={{'flexDirection': 'column', 'paddingLeft': '1em', 'paddingRight': '1em', 'width': '3rem', 'height': '3rem'}}>
                      {this.state.defaultButton === true ? (
													<button className={'actionableButton deleteBtn'} style={{'margin': '0px', 'boxShadow': 'unset', 'alignSelf': 'center', 'marginLeft': '1em'}} onClick={() => { this.props.deleteRow(this.props.name, i) }}/>
												) : this.props.customButton(rowObj, i)
											}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
         </table>
      </div>
    ) : null
  }
};
