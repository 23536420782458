import React, { Component } from 'react';
import './index.css';
import { Redirect } from 'react-router-dom'

export class LogOutScreen extends Component {
  componentDidMount() {
    return this.props.signOut()
  }

  render() {
    var loginRedirectJSX = <Redirect to={`/home`} push />;
    var currentUser = this.props.currentUser;
    return currentUser ? (
      <p>Logging off...</p>
    ) : loginRedirectJSX;
  }
}