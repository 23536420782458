import React, { Component } from 'react';
import './index.css';
import { auth } from '../../datastore/firebase';
import { Redirect } from 'react-router-dom'

export class LoginScreen extends Component {
	constructor (props) {
		super(props)
		this.state = {
      login : {
        email: '',
        password: '',
      },
      error: {
        code: null,
        message: null
      },
      showPass: false
    }

    this.signIn = this.signIn.bind(this);
    this.updateLoginState = this.updateLoginState.bind(this);
  }
  
  signIn() {
    auth.signInWithEmailAndPassword(this.state.login.email, this.state.login.password)
    .then(function(currentUser){
      return this.props.updateUser(currentUser);
    }.bind(this))
    .catch(function(error){
      var errorCode = error.code;
      var errorMessage = error.message;
      this.setState({
        'error': {
          code: errorCode,
          message: errorMessage
        }
      })
    }.bind(this))
  }
  
  friendlyError(code) {
    if(code === null)
      return;
    
    switch(code) {
      case 'auth/user-not-found':
        return 'Account not found, register with a developer!';
      case 'auth/wrong-password':
        return 'Invalid password!';
      case 'auth/internal-error':
        return 'Google is having issues right now, refresh this page or redo your last action :('
      default:
        return code;
    }
  }
  
  updateLoginState(event) {
    var name = event.target.name;
    var val = event.target.value;
    var curState = this.state.login;
    switch(name) {
      case 'email':
        curState.email = val;
        this.setState({ login : curState });
        break;
      case 'password':
        curState.password = val;
        this.setState({ login : curState });
        break;
      default:
        break
    }
  }
  
  render() {
    var friendlyErrorMessage = this.friendlyError(this.state.error.code);
    var errorMessage = {
      'email': this.state.error.code !== 'auth/wrong-password' ? friendlyErrorMessage : null,
      'password': this.state.error.code === 'auth/wrong-password' ? friendlyErrorMessage : null
    };
    
    var inputType = {
      'password': this.state.showPass === true ? 'text': 'password'
    }
    
    return this.props.redirectToHomePage === true ? (
      <Redirect to={`/home`} push />
    ):(
      <div className="login-container">
        <div className="login-form">
          <p className="login-title">Correction Tool Login</p>
          <div className="input-field">
            <input className="email-field" placeholder={'Email'} name={'email'} value={this.state.login.email} onChange={(event) => {this.updateLoginState(event)}} />
            <div className="error-message">
              <p>{errorMessage.email}</p>
            </div>
          </div>
          <div className="input-field">
            <input className="password-field" type={inputType.password} placeholder={'Password'} name={'password'} value={this.state.login.password} onChange={(event) => {this.updateLoginState(event)}} />
            <div className="error-message">
              <p>{errorMessage.password}</p>
            </div>
          </div>
          <div className="show-password-field">
            <input type={'checkbox'} onChange={(event) => {this.setState({ showPass : !this.state.showPass})}}/>
            <p>Show password</p>
          </div>
          <button className="submit-button" onClick={() => {return this.signIn()}}>login</button>
        </div>
      </div>
    );
  }
}