import React, { Component } from 'react';
import Lottie from 'react-lottie';
import confirmationAnimData from '../../datastore/animations/confirmation.json';
import { CSSTransition } from 'react-transition-group';
import './index.css';

export default class ApprovedScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isStopped: false,
			isPaused: false,
			tm: null
		};

		this.confirmBGColor = 'rgba(46, 204, 113,1.0)';
	}

	componentWillUnmount() {
		this.resetFunc()
	}

	componentDidMount() {
		this.timeoutFunc()
	}

	timeoutFunc = () => {
		var tm = setTimeout(() => {
			console.log("[isApproved] time to stop!")
			this.resetFunc()
		}, 1750);
		console.log("[isApproved] update", tm)
		this.setState({tm: tm})
	}

	resetFunc = () => {
		console.log("[isApproved] time to set state", this.state.isStopped)
		this.setState({
			'isStopped': true,
			'isPaused': false,
			'tm': null
		})
		clearTimeout( this.state.tm )
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: confirmationAnimData,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice'
			}
		};

		const approvedJSX = (
			<div key="loaderJSX" className={'confirmScreen'} style={{ 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'backgroundColor': this.confirmBGColor, position: 'sticky', 'top': '0px', 'left': '0px', 'zIndex': 1 }}>
				<div style={{ 'display': 'inline-block', 'width': '15em', 'height': '15em', 'backgroundColor': 'rgba(0, 0, 0, 0)', 'alignSelf': 'center', 'top': '0px', 'left': '0px', 'zIndex': 2 }}>
					<Lottie options={defaultOptions}
						isStopped={this.state.isStopped}
						isPaused={this.state.isPaused}
					/>
				</div>
				<p style={{ 'alignSelf': 'center', 'fontSize': '3em', 'paddingBottom': '1em', 'color': '#ecf0f1' }}>{`JOB #${this.props.jid} APPROVED!`}</p>
				<div style={{ 'width': '100vw', 'height': '100vh', position: 'absolute', 'top': '0px', 'left': '0px', 'zIndex': 3 }}  onClick={() => this.resetFunc()}></div>
			</div>
		)

		var isApproved = this.props.isApproved();
		//console.log("isApproved", isApproved)

		return (
			<CSSTransition
				in={ isApproved === true && this.state.isStopped === false && this.props.showAnimation === true }
				timeout={1250}
				classNames="confirmScreen"
				unmountOnExit
			>
				{approvedJSX}
			</CSSTransition>
		);
	}
};

/*
		OnClick handler to show
		OnClick handler to hide

		<button style={buttonStyle} onClick={() => this.setState({isStopped: true})}>stop</button>
		<button style={buttonStyle} onClick={() => this.setState({isStopped: false})}>play</button>
		<button style={buttonStyle} onClick={() => this.setState({isPaused: !this.state.isPaused})}>pause</button>
*/
