import React, { Component } from 'react';
import ImportReceiptComponent from '../job-import-receipts/index'
import AccordionTotals from './AccordionTotals/index';
import './index.css';

const KEY_CODES = Object.freeze({
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
  UP: 38,
  DOWN: 40,
});

export default class JobSummaryFooter extends Component {
	constructor (props) {
		super(props)
		this.state = {
      items: [],
      expenseData: null
    }
    this.checkTotals = this.checkTotals.bind(this);
  }

  componentDidMount() {
    return this.props.mongo.getItemizedList().then(itemList => {
      console.log("itemList", itemList)

      return this.setState({
        'items': itemList
      });
    })
  }

  checkTotals() {
      return Promise.all(this.state.items.map((itemObj, i) => {
        itemObj.amount = 0;
        return Promise.all([this.props.expenseData.map((expenseObj, j) => {
            console.log("expenseObj", expenseObj)
            if(expenseObj.type && expenseObj.amount)
            {
              console.log(expenseObj.type.value, itemObj.field)
              if(expenseObj.type.value === itemObj.field)
                itemObj.amount += +parseFloat(expenseObj.amount);
            }

            return itemObj.amount;
          }), this.props.laborData.map((laborObj, i) => {
						console.log("laborObj", laborObj)
						if(laborObj.billed && laborObj.rate)
						{
							console.log("laborObj-", "labor", itemObj.field)
							if("labor" === itemObj.field)
								itemObj.amount += +parseFloat( (laborObj.billed / 3600) * laborObj.rate );
						}

						return itemObj.amount;
					})])
      }))
  }


  buttonHandleKeyEvents = (event, func) => {
    switch (event.keyCode) {
      case KEY_CODES.SPACE:
      case KEY_CODES.RETURN:
        event.preventDefault();
      	func()
        break;
      default:
        break;
    }
  }

  render() {
    var itemList = this.state.items || [];
    var itemHashMap = {};
    if(itemList === null)
      itemList = [];

    this.checkTotals();

    this.props.billedHours.map((billedHoursObj, i) => {
      return Object.values(billedHoursObj).map(billedHour => {
        var key = Object.keys(billedHoursObj)[0];
        if(itemHashMap[key] === undefined)
            itemHashMap[key] = {};

        itemHashMap[key].jobRate = 69.00; //TODO: Pull from vWorkData
        itemHashMap[key].billedHours = +parseFloat(+parseFloat((billedHour / 60) / 60));
        console.log(`itemHashMap[${key}] => ${JSON.stringify(itemHashMap[key])}`, billedHour, itemHashMap[key].billedHours)
        return this.props.jobRateOverride.map(jobRateData => {
          var theKey = Object.keys(jobRateData)[0];
          if(theKey !== key)
            return null;

          if(itemHashMap[theKey] === undefined)
            itemHashMap[theKey] = {};

          itemHashMap[theKey].jobRate = +parseFloat(jobRateData[theKey]);
          console.log(`> itemHashMap[${theKey}] => ${JSON.stringify(itemHashMap[theKey])}`, itemHashMap[theKey].billedHours, billedHour)
          itemHashMap[theKey].billedHours = +parseFloat(+parseFloat((billedHour / 60) / 60));
          console.log(`> itemHashMap[${theKey}] => ${JSON.stringify(itemHashMap[theKey])}`, itemHashMap[theKey].billedHours, itemHashMap[theKey].jobRate)
          return itemHashMap[theKey];
        })
      })
    })

    itemList.map(item => {
      switch(item.field) {
        case 'time':
          //item.amount = +parseFloat(timeToDollarsCalculation);
          console.log("itemHashMap", itemHashMap)
          return Object.values(itemHashMap).map(itemObj => {
            console.log("jobRate", itemObj.jobRate)
            console.log("billedHours", itemObj.billedHours)
            var hoursToDollars = +parseFloat(itemObj.jobRate * itemObj.billedHours);
            item.amount += hoursToDollars;
            console.log('case - time', itemObj, item.amount)
            return item.amount;
          })
        default:
          break;
      }
      return item.amount;
    })

    var totals = 0.0;
    itemList.map(item => {
      switch(item.field) {
        default:
          return totals += +parseFloat(item.amount).toFixed(2)
      }
    });

    return (
      <div className={'job-totals-info-main'} style={{'marginTop': '2em'}}>
        <div className={'job-totals-info-main left'} style={{ 'margin' : '0px', marginBottom: '2rem' }}>
          <div className='job-actions-container no-print'>
						<h3 className={'no-print'} style={{ 'margin' : '0px' }} >Actions</h3>
						<div style={{display: 'inline-flex', flexDirection: 'row' }}>
							<div>
		            <div onClick={() => { return this.props.actionables.approve() }} className='job-actions-tile no-select' role="button" tabindex="0" aria-pressed="false" onKeyDown={(e) => this.buttonHandleKeyEvents(e, this.props.actionables.approve)}>
		              <img alt={this.props.statuses.approved.value !== true ? "approve" : "approved"} src={this.props.statuses.approved.value !== true ? "../img/key-go.png" : "../img/accept.png"} />
		              <p>{this.props.statuses.approved.value !== true ? "Approve" : `Approved ${ this.props.statuses.approved.creator !== undefined ? ('by ' + this.props.statuses.approved.creator.substring(0, 6)) : '' }` }</p>
		            </div>
							</div>
							<div>
		            <div onClick={() => { return this.props.actionables.charge(totals) }} className='job-actions-tile no-select' role="button" tabindex="0" aria-pressed="false" onKeyDown={(e) => this.buttonHandleKeyEvents(e, (function(totals){ return this.props.actionables.charge(totals)}.bind(this)) )}>
		              <img alt={this.props.statuses.charged.value !== true ? "charge" : "card charged"} src={this.props.statuses.charged.value !== true ? "../img/creditcards.png" : "../img/accept.png"} />
		              <p>{this.props.statuses.charged.value !== true ? "Charge" : `Charged ${ this.props.statuses.charged.creator !== undefined ? ('by ' + this.props.statuses.charged.creator.substring(0, 6)) : '' } for ${ this.props.statuses.charged.amount !== undefined ? ('$' + parseFloat(this.props.statuses.charged.amount).toFixed(2)) : '' }`}</p>
		            </div>
							</div>
							<ImportReceiptComponent firebaseStorage={this.props.firebaseStorage} jid={this.props.jid}/>
						</div>
          </div>
        </div>
        <div className={'job-totals-info right'} style={{ 'margin' : '0px' }}>
          <AccordionTotals itemList={itemList} itemHashMap={itemHashMap} totals={totals} />
        </div>
      </div>
    );
  }
};
