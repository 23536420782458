import React, { Component } from 'react';
import './index.css';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class DropdownCell extends Component {
	constructor (props) {
		super(props)
		this.state = {
      mode: 'display',//((props.editable !== false && props.name !== 'techs') ? 'edit' : 'display'),
      value: {
        label: null,
        value: null
      }
    }
    this._onSelect = this._onSelect.bind(this);
  }

  _onSelect(value) {
    if(value === null)
    {
      value = {};
      value.label = null;
      value.value = null;
    }
    console.log("DropdownCell > _onSelect", value)
    this.setState({
      'value': value,
      'mode': 'display'
    })
    return this.props.updateRow(this.props.name, this.props.fieldIndx, {[this.props.fieldName] : value})
  }

  componentDidMount() {

    let value = Object.assign(this.state.value, this.props.value);
    let canShow = false;
    console.log("DropdownCell > value", value)
    if(this.props.value) {
			console.log("DropdownCell > value.value", value.value)
			if(value.value) {
				canShow = true;
			}
		}
    return this.setState({
      'value': value,
      'mode': ( canShow === true ? 'display': 'edit' )
    })


		/*
		let value = Object.assign(this.state.value, this.props.value);
		this.setState({
      'value': value,
      'mode': 'display'
    })*/
  }

  componentDidUpdate(prevProps, prevState) {
    let previousValue = prevProps.value || null;
    let newValue = this.props.value || null;
    if (prevProps.value !== this.props.value) {
      console.log('[DROPDOWNCELL]', previousValue, newValue)
      if(previousValue === null || previousValue === undefined) {
        this.setState({'mode': 'display', 'value': newValue})
      }  else if ( previousValue != null && newValue != null ) {
				this.setState({'mode': 'display', 'value': newValue})
			} else {
				this.setState({'mode': 'display', 'value': { label: null, value: null }})
			}
    }
  }

  render() {
    var dropdownName = "Select option";
    var displayVal = null;
    try {
      displayVal = this.props.value.label;
    } catch(e) {
      console.log("[FAILED]", this.props.value)
      displayVal = null;
    }

    try {
      displayVal = this.state.value.label;
    } catch(e) {
      console.log("[FAILED]", this.props.value)
      displayVal = null;
    }

    try {
      let dropdownTmp = this.props.dropdown;
      dropdownName = "Select " + dropdownTmp.name;
    } catch(e) {
      dropdownName = "Select option";
    }

    var displayModeJSX = (
      <div style={{'display': 'flex', 'flexDirection': (this.props.value === null ? 'column': 'row'), 'justifyContent': 'space-evenly'}}>
        <p className={'tbl-cell-print-fix'} style={{'display': (this.props.value === null ? 'none': 'inline-block'), 'marginBottom': '0px', 'marginTop': ( this.props.editable === false ? '0.5em': '0.5em' ), fontSize: 'calc(0.75vw + 0.5rem)' }}>{displayVal}</p>
        <div className={'input-action-buttons no-select no-print'} style={{'alignSelf': 'flex-end', 'display': ( this.props.editable === false ? 'none': 'inline-block'), width: '3rem', height: '3rem'}}>
          <button role="presentation" aria-hidden="true" onClick={() => { this.setState({ mode: 'edit' }) }} className={this.props.value === null ? "actionableButton editAddBtn" : "actionableButton editBtn"} alt={this.props.value === null ? 'add' : 'edit'}/>
        </div>
      </div>
    );

    var editModeJSX = (
      <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-evenly'}}>
        <div className={'job-fix-select'}>
          <Select tabIndex={this.props.tabIndex} options={this.props.options} onChange={(value) => {this._onSelect(value)}} value={this.state.value} placeholder={dropdownName} />
        </div>
        <div className={'input-action-buttons'} style={{'width': '8em'}}>
          <button role="presentation" aria-hidden="true" onClick={() => { this.setState({ mode: 'display', value: (this.props.value || this.state.value) }) }} className="actionableButton cancelBtn">
          </button>
        </div>
      </div>
    );

    switch(this.state.mode) {
      case 'display':
        return displayModeJSX;
      case 'edit':
        return editModeJSX;
      default:
        return displayModeJSX;
    }
  }
};
