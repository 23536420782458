import React, { Component } from 'react';
import Badges from '../badges/index'; //subcomponent
import './index.css';
//import Autosuggest from 'react-autosuggest';
import moment from 'moment';
import Clipboard from 'react-clipboard.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import GoogleMapReact from '../job-google-map/index';
import SplitPane from 'react-split-pane';

//CT number
// var specialId = null;
/*
        <div className={'job-sub-info no-print'}>
          <div style={{ 'display': 'flex', 'flexDirection': 'column', 'justifyContent' : 'normal' }} className={'job-sub-info left no-margin'}>
            <input className={'job-coupon'} placeholder="Coupon Applied" />
            <textarea className={'job-explanation'} placeholder="Explanation" />
          </div>
        </div>


// Imagine you have a list of languages that you'd like to autosuggest.
const languages = [
  {
    name: 'Form name here'
  },
  {
    name: 'Form name here 2'
  }
];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : languages.filter(lang =>
    lang.name.toLowerCase().slice(0, inputLength) === inputValue
  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.name}
  </div>
);

class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: []
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Search iPad system',
      value,
      onChange: this.onChange
    };

    // Finally, render it!
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}
*/

export default class JobSummaryHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      elementWidth: 0,
      data: {
        jobData: {
          actual_duration: 0,
          actual_end_at: null,
          actual_start_at: null,
          alarm_at: null,
          cell_point_date: null,
          code: null,
          created_at: null,
          created_by_name: null,
          custom_fields: [],
          BusinessToBusinessType: null,
          customerType: null,
          customer_id: null,
          customer_name: null,
          customer_third_party_id: null,
          deleted_at: null,
          equipment: [],
          group_ids: [],
          has_pod: false,
          id: null,
          invoice: null,
          is_quote: false,
          isRepeatCustomer: "",
          jobType: null,
          job_type: "job",
          multicast_workers: [],
          negative_time_windows: false,
          notes: [],
          paused_at: null,
          planned_duration: 0,
          planned_end_at: null,
          planned_start_at: null,
          postponed: false,
          published_at: null,
          published_by_name: null,
          quote_accepted: false,
          recurring_job_id: null,
          recurring_job_name: null,
          regionCode: null,
          requested_at: null,
          required_capacity: null,
          status: null,
          steps: [],
          template_id: 0,
          template_name: null,
          third_party_id: null,
          time_windows: [],
          updated_at: null,
          worker_id: 0,
          worker_name: null,
          _id: null
        },
        subJobs: [],
        technicians: []
      },
      customerData: {
        ready: false,
        name : null,
        first_name : null,
        last_name : null,
        phone : null,
        mobile : null,
        email : null,
        address1 : null,
        address2 : null,
        city : null,
        post_code : null,
        state : null,
        formatted_address : null,
        lat : 0.0,
        lng : 0.0
      },
      reportDate: props.reportDate,
      mode: {
        reportDate: 'display'
      }
    };

    console.log("Fetching job information...", this.props.jid)
    this.props.mongo.getvWorkJobData(this.props.jid).then(data => {
      console.log("[JobSummaryHeader] jobData =>", data)
      return this.setState({'data': data})
    }).then(() => {
      setTimeout(function(){
        let cid = this.state.data.jobData.customer_id;
        console.log("[JobSummaryHeader] fetching cid #" + cid + "...")
        return this.props.mongo.getCustomerData(cid).then(newData => {
          newData.ready = true;
          console.log("[JobSummaryHeader] customerData =>", newData)
          this.setState({
            customerData: newData
          })
        })
      }.bind(this), 2000)
    })

    this.dividerDiv = React.createRef();
    this.saveNewSize = this.saveNewSize.bind(this);
  }

  modifyField( dataField, newMode ) {
    let newState = this.state.mode;
    newState[dataField] = newMode;
    return this.setState({mode: newState});
  }

  componentDidMount() {
    this.setState({elementWidth: this.dividerDiv.clientWidth})
  }

  saveNewSize(event) {
    console.log(event)
  }

  render() {
    var reportDateDisplayJSX = () => {
      return (
        <Clipboard data-clipboard-text={this.props.reportDate.format("MMMM Do YYYY")} component="div" className={'select-text job-detail-text'} >
          <div style={{'display': 'flex', 'flexDirection': 'row'}}>
            <p style={{'margin': '0'}}>Report Date: {this.props.reportDate.format("MMMM Do YYYY")}</p>
            <button onClick={() => { return this.modifyField('reportDate', 'edit') } } className={"actionableButton editBtn"} alt={'edit'} style={{'display': 'inline-block', 'height': '1.5em', 'width': '1.5em', 'marginLeft': '0.5em'}} />
          </div>
        </Clipboard>
      );
    };

    var reportDateEditJSX = () => {
      return (
        <div style={{'display': 'flex', 'flexDirection': 'row'}}>Report Date:<DatePicker selected={this.props.reportDate} onChange={(date) => {return this.props.updateReportDate(date)}} /><button onClick={() => { return this.modifyField('reportDate', 'display') } } className={"actionableButton saveBtn"} alt={'edit'} style={{'height': '2em', 'width': '2em', 'marginLeft': '0.5em'}} />
        </div>
      );
    };

    var reportDateJSX = () => {
      return this.state.mode.reportDate === 'display' ? reportDateDisplayJSX() : reportDateEditJSX();
    };

    var actual = {
      start_at: moment( this.state.data.jobData.actual_start_at ),
      end_at: moment(this.state.data.jobData.actual_end_at)
    }

    if(!actual.start_at.isValid())
      actual.start_at = "N/A (Job hasn't started)";
    else
      actual.start_at = actual.start_at.format("MMMM Do YYYY, h:mm a");

    if(!actual.end_at.isValid())
      actual.end_at = "N/A (Job hasn't ended)";
    else
      actual.end_at = actual.end_at.format("MMMM Do YYYY, h:mm a");

    var planned = {
      start_at: moment(this.state.data.jobData.planned_start_at).format("MMMM Do YYYY, h:mm a"),
      end_at: moment(this.state.data.jobData.planned_end_at).format("MMMM Do YYYY, h:mm a")
    }

    var timestamps = {
      contractor_signature: actual.start_at,
      customer_signature: actual.end_at,
      planned_start_at: planned.start_at,
      planned_end_at: planned.end_at
    }

    var tpid = this.state.data.jobData.third_party_id;
    var customerName = this.state.data.jobData.customer_name;
    var address = null;
    var address2 = null;
    var gps_coords = {'lat': 0, 'lng': 0, 'text': 'Tech hits start'}
    try {
      address = this.state.data.jobData.steps[0].location.formatted_address;
      let addrArray = address.split(',');
      address = addrArray[0];
      addrArray.splice(addrArray.length-1, 1);
      address2 = "";
      for(let i = 1; i < addrArray.length; i++) {
        if(i !== (addrArray.length - 1))
        {
          address2 += (addrArray[i] + ',');
        } else {
          address2 += addrArray[i];
        }
      }
      console.log("address2", address2)
    }catch(e){
      address = "Address not found"
    }

    try {
      gps_coords.lat = this.state.data.jobData.steps[0].location.lat;
      gps_coords.lng = this.state.data.jobData.steps[0].location.lng;
    } catch (e) {
      gps_coords.lat = 41.894072;
      gps_coords.lng = -87.642306;
    }

    var gps_locations = [];
    gps_locations.push(gps_coords);

    try {
      gps_locations.push({'lat': this.state.data.jobData.steps[1].location.lat, 'lng': this.state.data.jobData.steps[1].location.lng, 'text': `Tech hits stop (${moment(this.state.data.jobData.steps[1].completed_at).format('LLL')})`})
    } catch (e) {}

    var creatorName = this.state.data.jobData.created_by_name;
    var altCreatorName = this.state.data.jobData.SalesmanOverride;
    if(altCreatorName === "")
      altCreatorName = undefined;
    var regionCode = this.state.data.jobData.regionCode;
    var jobType = this.state.data.jobData.jobType;
    var customerType = this.state.data.jobData.customerType;
    var BusinessToBusinessType = this.state.data.jobData.BusinessToBusinessType || null;
    var tpidWithoutDash = tpid;
    var isRepeatCustomer = "";
    try {
      isRepeatCustomer = this.state.data.jobData.isRepeatCustomer;
    } catch(e) {}

    try {
      tpidWithoutDash = tpid.split('-')[0];
    } catch(e) {}

    var customerFirstName = null;
    var customerLastName = null;

    try {
      let nameIndx = customerName.lastIndexOf(' ');
      customerFirstName = customerName.substring(0, nameIndx);
      customerLastName = customerName.substring(nameIndx + 1);
    } catch (e) {}

    try {
      customerFirstName = customerFirstName.toUpperCase()
      customerLastName = customerLastName.toUpperCase()
    } catch(e) {}

    if(this.state.customerData.first_name !== null)
      customerFirstName = this.state.customerData.first_name;

    if(this.state.customerData.last_name !== null)
      customerLastName = this.state.customerData.last_name;

    try {
      customerFirstName = customerFirstName.toUpperCase()
    } catch(e) {}

    try {
      customerLastName = customerLastName.toUpperCase()
    } catch(e) {}

    var regionCodeClipboard = null
    try {
      regionCodeClipboard = regionCode.split(' - ')[0]
    } catch(e) {}

    var phoneNumberJSX = (this.state.customerData.phone + " (Phone)") || (this.state.customerData.mobile + " (Mobile)")

    if(this.dividerDiv !== null)
    {
      console.log("elementWidth", this.state.elementWidth)
      console.log(localStorage.getItem('splitPos'))
      var paneDividerWidth = parseInt(localStorage.getItem('splitPos'), 10);
      if(isNaN(paneDividerWidth) || paneDividerWidth === 0)
      {
        paneDividerWidth = (this.state.elementWidth - 350);
      }
    }
    return (
      <div className={'job-print-header'}>
        <div className={'job-badge-container'}>
          <Badges names={['vWork']} vwork_jid={this.props.jid} />
          <Clipboard data-clipboard-text={this.props.jid} component="div" className={'job-number select-text'}>
            <p style={{'margin': '0'}}>#{this.props.jid}</p>
          </Clipboard>
          <Clipboard data-clipboard-text={tpidWithoutDash !== null ? tpidWithoutDash : this.props.jid} component="div" className={'job-number select-text'} style={{'paddingRight': '0.5em'}} >
            <p style={{'margin': '0'}}>({tpidWithoutDash !== null ? tpid : this.props.jid})</p>
          </Clipboard>
          <Clipboard data-clipboard-text={customerLastName} component="div" className={'job-number select-text'} style={{'paddingLeft': '0px'}} >
            <p style={{'margin': '0', 'marginRight' : '0'}}>{customerLastName}</p>
          </Clipboard>
          <div className={'job-number-unpadded select-text-nostyle'}>
            <p style={{'margin': '0'}}>{', '}</p>
          </div>
          <Clipboard data-clipboard-text={customerFirstName} component="div" className={'job-number select-text'} style={{'paddingLeft': '0px'}} >
            <p style={{'margin': '0'}}>{customerFirstName}</p>
          </Clipboard>
          <div className={'job-number-unpadded select-text-nostyle'}>
            <p style={{'margin': '0', 'marginLeft': '0.25em'}}>{' | '}</p>
          </div>
          <Clipboard data-clipboard-text={customerLastName + ', ' + customerFirstName} component="div" className={'job-number select-text'} style={{ 'paddingLeft': '0px' }} >
            <p style={{'margin': '0'}}>{customerLastName + ', ' + customerFirstName}</p>
          </Clipboard>
          <div className={'job-number-unpadded select-text-nostyle'}>
            <p style={{'margin': '0', 'marginLeft': '0.25em'}}>{' | '}</p>
          </div>
          <Clipboard data-clipboard-text={customerFirstName + ' ' + customerLastName} component="div" className={'job-number select-text'} style={{ 'paddingLeft': '0px' }} >
            <p style={{'margin': '0'}}>{customerFirstName + ' ' + customerLastName}</p>
          </Clipboard>
        </div>
        <div className={'job-detail'} style={{'display': 'flex', 'flexDirection': 'column'}}>
          <div className={'job-detail-text'} style={{'display': 'flex', 'flexDirection' : 'row'}}>
            <Clipboard data-clipboard-text={timestamps.planned_start_at} component="div" className={'select-text job-detail-text'} >
              <p>Date of Service: {timestamps.planned_start_at}</p>
            </Clipboard>
          </div>
          <div className={'job-detail-text'} style={{'display': 'flex', 'flexDirection' : 'row'}}>{reportDateJSX()}</div>
        </div>
        <div className={'job-sub-info'} ref={element => this.dividerDiv = element}>
          <SplitPane split="vertical" allowResize={true} minSize={400} defaultSize={paneDividerWidth} onChange={ size => (this.state.elementWidth !== 0 ? localStorage.setItem('splitPos', size) : null)} style={{'position': 'unset'}}>
            <div className={'job-sub-info left'}>
              <div style={{'display': 'flex', 'flexDirection': 'row'}}>
                <Clipboard data-clipboard-text={address} component="div" className={'select-text'}>
                  <p>Job Address: {address},</p>
                </Clipboard>
                <Clipboard data-clipboard-text={address2} component="div" className={'select-text'}>
                  <p>{address2}</p>
                </Clipboard>
                <GoogleMapReact center={gps_coords} locations={gps_locations}/>
              </div>
              <Clipboard data-clipboard-text={this.state.customerData.phone || this.state.customerData.mobile} component="div" className={'select-text'} style={{'backgroundColor':  ( this.state.customerData.phone === null ? ( this.state.customerData.mobile === null ? '#e74c3c42' : '' ) : '' ) }}>
                <p>Phone number: { this.state.customerData.ready === false ? 'Fetching (please wait)...' : ( this.state.customerData.phone === null ? ( this.state.customerData.mobile === null ? 'Missing from vWork!' : phoneNumberJSX ) : phoneNumberJSX ) }</p>
              </Clipboard>
              <Clipboard data-clipboard-text={this.state.customerData.email} component="div" className={'select-text'} style={{'backgroundColor': (this.state.customerData.email === null ? '#e74c3c42' : '')}}>
                <p>Email: {this.state.customerData.ready === false ? 'Fetching (please wait)...' : ( this.state.customerData.email === null ? 'Missing from vWork!' : this.state.customerData.email )}</p>
              </Clipboard>
              {/* Start */}
              <Clipboard data-clipboard-text={timestamps.contractor_signature} component="div" className={'select-text'}>
                <p>Start datetime: {timestamps.contractor_signature}</p>
              </Clipboard>
              {/* End */}
              <Clipboard data-clipboard-text={timestamps.customer_signature} component="div" className={'select-text'}>
                <p>End datetime: {timestamps.customer_signature}</p>
              </Clipboard>
            </div>
            <div className={'job-sub-info right'}>
              <Clipboard data-clipboard-text={customerType} component="div" className={'select-text'} >
                <p>Customer Type: {customerType}</p>
              </Clipboard>
              <Clipboard data-clipboard-text={isRepeatCustomer} component="div" className={'select-text'} >
                <p>Repeat Customer: {isRepeatCustomer}</p>
              </Clipboard>
              <Clipboard data-clipboard-text={BusinessToBusinessType} component="div" className={'select-text'} >
                <p>B2B Type: {BusinessToBusinessType}</p>
              </Clipboard>
              <Clipboard data-clipboard-text={creatorName} component="div" className={'select-text'} >
                <p style={{ textDecoration: ( altCreatorName !== undefined ? "line-through" : "none" ) }}>Sales: {creatorName}</p>
              </Clipboard>
              {altCreatorName !== undefined ? (
                <Clipboard data-clipboard-text={altCreatorName} component="div" className={'select-text'} >
                  <p>(OVERRIDEN) Sales: {altCreatorName}</p>
                </Clipboard>
                ) : null
              }
              <Clipboard data-clipboard-text={regionCodeClipboard !== null ? regionCodeClipboard : regionCode} component="div" className={'select-text'} >
                <p>Region Code: {regionCode}</p>
              </Clipboard>
              <Clipboard data-clipboard-text={jobType} component="div" className={'select-text'} >
                <p>Job Type: {jobType}</p>
              </Clipboard>
            </div>
          </SplitPane>
        </div>
      </div>
    );
  }
};
