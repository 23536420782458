//TODO: Show
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import ReactModal from 'react-modal';
import './index.css';

const AnyReactComponent = ({ text }) => <div className={'job-google-map-marker'}><p>{text}</p></div>;

export default class SimpleMap extends Component {
  constructor () {
    super();
    this.state = {
      showModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal () {
    this.setState({ showModal: true });
  }

  handleCloseModal () {
    this.setState({ showModal: false });
  }

  static defaultProps = {
    center: {
      lat: 41.894072,
      lng: -87.642306
    },
    zoom: 16,
    locations: []
  };

  render() {
    return (
      <div>
        <button className={'job-google-map-btn'} onClick={this.handleOpenModal}>
          <span role="button" tabindex="0" aria-label="display map button">🗺️</span>
        </button>
        <ReactModal
           isOpen={this.state.showModal}
           contentLabel="Technician Telemetry Map"
        >
          <button className={'job-google-map-modal-btn'} onClick={this.handleCloseModal}></button>
          <div className={'job-google-map-container'}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyCYRwXb_4vL5pot4o0N_a5uRxvQBpr0q4M" }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
            >
              <AnyReactComponent
                lat={41.894072}
                lng={-87.642306}
                text={'BRH Office Location'}
              />
              {this.props.locations.map((locationData, i) => {
                return (
                  <AnyReactComponent
                    key={'location_' + i}
                    lat={locationData.lat}
                    lng={locationData.lng}
                    text={locationData.text}
                  />
                );
              })}
            </GoogleMapReact>
          </div>
        </ReactModal>
      </div>
    );
  }
}
