import axios from 'axios';
import Promise from 'bluebird';

const fetchTechnicianList = () => {
  console.log("fetchTechnicianList()")
  return axios({
    'method': 'get',
    'url': "https://api.brhapps.com/v1/List/TechCodes",
    'responseType': "json"
  }).then(function(response){
    console.log("fetchTechnicianList", response.data)
    if(response.status === 200)
      return response.data;
    else
      throw response.data.error;
  }).catch(function(error){
    //TODO: Send back to motherbase!
    console.error(error);
    return {'success': false, 'technicianCodes': null}
  })
}

//TODO: Fetch this list from the server!
const getCreatorList = () => {
  console.log("getCreatorList()")
  return [{'first_name': 'Dean', 'last_name': 'Elleman', 'role': 'accountant'},{'first_name': 'Stephanie', 'last_name': 'Hoerr', 'role': 'accountant'}, {'first_name': 'Sam', 'last_name': 'Cervantes', 'role': 'coordinator'}, {'first_name': 'Dirk', 'last_name': 'Hoerr', 'role': 'administrator'}, {'first_name': 'Sergio', 'last_name': 'German', 'role': 'administrator'},{'first_name': 'Brad', 'last_name': 'Melgoza', 'role': 'sales_manager'},{'first_name': 'Aaron', 'last_name': 'Minnema', 'role': 'salesman'},{'first_name': 'Nick', 'last_name': 'Lawrie', 'role': 'salesman'},{'first_name': 'Jerome', 'last_name': 'Robinson', 'role': 'salesman'},{'first_name': 'Michael', 'last_name': 'Williams', 'role': 'salesman'}];
}

const getItemizedList = () => {
  console.log('[const getItemizedList] returning itemList...')
  return [{'name': 'Labor', 'field': 'labor', 'amount': 0.0},{'name': 'Materials', 'field': 'materials', 'amount': 0.0}, {'name': 'Parking', 'field': 'parking', 'amount': 0.0}, {'name': 'Refuse', 'field': 'refuse', 'amount': 0.0}, {'name': 'Equipment', 'field': 'equipment', 'amount': 0.0}, {'name': 'Other', 'field': 'other', 'amount': 0.0}, {'name': 'Tip', 'field': 'tip', 'amount': 0.0}, {'name': 'Discounts', 'field': 'discounts', 'amount': 0.0},{'name': 'Charges', 'field': 'charges', 'amount': 0.0},{'name': 'Time', 'field': 'time', 'amount': 0.0}];
}

const fetchCustomerData = ( cid ) => {
  console.log("fetchCustomerData(" + cid + ")")
  return axios({
    'method': 'get',
    'url': ("https://api.brhapps.com/v1/List/CustomerData?cid=" + cid),
    'responseType': "json"
  }).then(function(response){
    console.log("fetchCustomerData", response.data)
    if(response.status === 200)
      return response.data;
    else
      throw response.data.error;
  }).catch(function(error){
    //TODO: Send back to motherbase!
    console.error(error);
    return {'success': false, 'customerData': null}
  })
}

const getJobData = ( jid ) => {
  console.log("getJobData(" + jid + ")")
  return axios({
    method: 'get',
    url: ("https://api.brhapps.com/v1/CorrectionTool/" + jid),
    responseType: "json"
  }).then(function(response){
    //TODO: Inject 'techs' into response.data
    return response;
  })
}

const getIPAddress = () => {
  return axios({
    method: 'get',
    url: "https://api.ipify.org?format=json",
    responseType: "json"
  }).then(function(response){
    return response.data.ip || null;
  })
}

class MongoDB {
  constructor() {
    this.vWorkData = null;
    this.formData = null;
    this.customerData = null;
    this.getAllData = this.getAllData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.getvWorkJobData = this.getvWorkJobData.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.getCustomerData = this.getCustomerData.bind(this);
    this.getTechList = this.getTechList.bind(this);
    this.getCreatorList = this.getCreatorList.bind(this);
    this.getItemizedList = this.getItemizedList.bind(this);
  }

  fetchData( jid ) {
    return getJobData( jid )
      .then( newData => {
        let theData = newData.data;
        console.log(theData)
        this.formData = theData.data;
        this.vWorkData = theData.job;
        return {'formData': this.formData, 'vWorkData': this.vWorkData};
    })
  }

  getvWorkJobData( jid ) {
    if(this.vWorkData === null)
    {
      return new Promise(function(resolve, reject){
        return this.fetchData(jid)
          .then(newState => {
            console.log("[MongoDB] this.getvWorkJobData( " + jid + " )", newState)
            return resolve(newState.vWorkData);
        })
      }.bind(this))
    } else {
      return new Promise(function(resolve, reject){
        return resolve(this.vWorkData)
      }.bind(this))
    }
  }

  getFormData( jid ) {
    if(this.formData === null)
    {
      return this.fetchData(jid).then(newState => {
        return newState.formData;
      })
    } else return this.formData;
  }

  getCustomerData( cid ) {
    let cData = this.customerData || {};
    if(Object.keys(cData).length <= 0) {
      return fetchCustomerData(cid).then(newState => {
        return newState.customerData;
      })
    } else return this.customerData;
  }

  getAllData( jid ) {
    /*if(this.formData === null || this.vWorkData === null)
    {*/
      return this.fetchData(jid).then(newState => {
        return newState;
      })
    //}
  }

  //TODO: Pull remotely (maybe firebase?)
  getTechList() {
    console.log('[MongoDB] this.getTechList()')
    return fetchTechnicianList()
  }

  //TODO: Pull remotely (maybe firebase?)
  getCreatorList() {
    console.log('[MongoDB] this.getCreatorList()')
    return getCreatorList()
  }

  //TODO: Pull remotely (maybe firebase?)
  getItemizedList() {
    console.log('[MongoDB] this.getItemizedList()')
    return new Promise(function(resolve, reject){
      return resolve(getItemizedList());
    });
  }

  getUserIp() {
    return getIPAddress();
  }
}

export default MongoDB;
