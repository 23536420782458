import React, { Component } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export default class PDFViewer extends Component {
	constructor (props) {
		super(props)
		this.state = {
		    numPages: null,
		    pageNumber: 1,
		    pdfDoc: null
		}
		this.onDocumentLoad = this.onDocumentLoad.bind(this);
		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
	}

	onDocumentLoad(pdf) {
		this.setState({ 'pdfDoc': pdf, 'numPages': pdf.numPages });
	}

	prevPage() {
		var currentNumber = this.state.pageNumber;
		var nextNumber = currentNumber - 1;

		if(nextNumber > 0)
			this.setState({ pageNumber: nextNumber })
		else
			console.log("No previous page to navigate to!")
	}

	nextPage() {
		var currentNumber = this.state.pageNumber;
		var nextNumber = currentNumber + 1;

		if(nextNumber <= this.state.numPages)
			this.setState({ pageNumber: nextNumber })
		else
			console.log("No next page to navigate to!")
	}

	render() {
		var pageNum = this.state.pageNumber;
		var numPages = this.state.numPages;
		var fileName = this.props.fileName;

		return (
			<div>
				<div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '100%' }}>
					<p style={{'margin': '0px', 'fontSize': '1.25em', 'fontWeight': 'bold',  'marginRight': '1em'}}>{fileName}</p>
					<div style={{ 'display': 'inline-flex', 'flexDirection': 'row', 'justifyContent': 'space-evenly'}}>
						<p style={{'margin': '0px', 'fontSize': '1.25em'}}>Page {pageNum} of {numPages}</p>
						<div style={{ 'display': 'inline-flex', 'flexDirection': 'row', 'paddingLeft': '1em', 'justifyContent': 'space-evenly'}}>
							<button disabled={ numPages === 1 } style={{ 'display': 'inline-block', 'flexDirection': 'row', 'fontSize': '1.25em'  }} onClick={() => { this.prevPage() }}>Prev</button>
							<button disabled={ numPages === 1 } style={{ 'display': 'inline-block', 'flexDirection': 'row', 'fontSize': '1.25em', 'marginLeft': '0.5em' }} onClick={() => { this.nextPage() }}>Next</button>
						</div>
					</div>
				</div>
				<hr />
				<Document
					file={'https://cors-anywhere.herokuapp.com/' + this.props.fileUrl}
					onLoadSuccess={this.onDocumentLoad}
				>
					<Page pageNumber={pageNum} />
				</Document>
			</div>
		);
	}

}
