import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/minimal-example.css';

export default class AccordionTotals extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isExpanded: true
    }

    this.onChange = this.onChange.bind(this);
  }

  onChange(newState) {
      this.setState({
        isExpanded: newState
      })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextState.isExpanded !== this.state.isExpanded) {
      if(nextState.isExpanded !== false) {
        return false;
      } else {
        return true;
      }
    } else if(nextState.isExpanded === this.state.isExpanded) {
      return true;
    } else {
      return true;
    }
  }

  render() {
    var totals = this.props.totals;
    var itemList = this.props.itemList;

    return (
      <Accordion onChange={() => this.onChange(!this.state.isExpanded)}>
          <AccordionItem expanded={this.state.isExpanded}>
              <AccordionItemTitle>
                  <h3 style={{'marginBottom': ( this.state.isExpanded === true ? '-1em' : '17.5px' ), 'paddingTop': '1em'}}>Itemized totals</h3>
                  <div className="job-totals-info" style={{'width' : '17.3em', 'opacity' : ( this.state.isExpanded === true ? '0' : '1' ) }}>
                     <div className="job-totals-info left">
                        <b>Total:</b>
                     </div>
                     <div className="job-totals-info right">
                        <b>${parseFloat(totals).toFixed(2)}</b>
                     </div>
                  </div>
                  <p className="accordion__hidden_text" style={{'marginTop' : ( this.state.isExpanded === true ? '1em' : '1em' ), 'marginBottom': '0'}}>Click to {( this.state.isExpanded === true ? 'collapse' : 'expand' )}</p>
              </AccordionItemTitle>
              <AccordionItemBody>
                <div className={'job-totals-info right'}>
                  <div className={'job-totals-info'}>
                    <div className={'job-totals-info left'}>
                      {itemList.map(item => {
                          return ( <p key={`${item.name}_title`}>{item.name}</p> );
                      })}
                    </div>
                    <div className={'job-totals-info right'}>
                      {itemList.map(item => {
                        return ( <p key={`${item.name}_amount`}>${parseFloat(item.amount).toFixed(2)}</p> );
                      })}
                    </div>
                  </div>
                  <div className={'job-totals-info'}>
                    <div className={'job-totals-info left'}>
                      <b>Total:</b>
                    </div>
                    <div className={'job-totals-info right'}>
                      <b>${parseFloat(totals).toFixed(2)}</b>
                    </div>
                  </div>
                </div>
              </AccordionItemBody>
          </AccordionItem>
      </Accordion>
    );
  }
}
