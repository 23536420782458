import React, { Component } from 'react';
import './index.css';
import moment from 'moment';
import momentDurationFormatSetup from "moment-duration-format";
//import NumericInput from 'react-numeric-input';
import AccountingInput from './brh-input-handlers/accounting-input/index';
momentDurationFormatSetup(moment);

const dollarAmountJSX = (amount, editable) => {
  if(amount === undefined || amount === null)
    amount = 0;

  return (
    <div style={{'display' : 'flex', 'flexDirection': 'row'}}>
      <p className={'tbl-cell-print-fix'} style={{'textAlign': 'left', 'marginBottom': '0px', 'marginTop': '0em'}} >{amount < 0 ? '-' : null}{isNaN(parseFloat(Math.abs(amount)).toFixed(2)) === true ? null : '$'}</p>
      <p className={'tbl-cell-print-fix'} style={{'textAlign': 'right', 'marginBottom': '0px', 'marginTop': '0em'}} >{isNaN(parseFloat(Math.abs(amount)).toFixed(2)) === true ? 'N/A' : parseFloat(Math.abs(amount)).toFixed(2)}</p>
    </div>
  )
}

const timeAmountJSX = (timeAsSeconds) => {
  var theVal = timeAsSeconds;
  if(theVal === undefined || theVal === null)
    theVal = 0;

  console.log('[TBLCell] timeAmountJSX', theVal)

  var clipSeconds = (theVal % 60);
  var finalSeconds = (theVal - clipSeconds)
  var timeAsMomentObject = moment.duration(finalSeconds, 'seconds');
  return finalSeconds !== 0 ? (
    <div style={{ 'display': 'flex', 'flexDirection': 'row' }}>
      <p style={{ 'marginTop': '0px', 'marginBottom': '0px' }}>{ timeAsMomentObject.format('hh:mm')}</p>
    </div>
  ) : (
    <div style={{ 'display': 'flex', 'flexDirection': 'row' }}>
      <p style={{ 'marginTop': '0px', 'marginBottom': '0px' }}>00:00</p>
    </div>
  )
}

export default class TblCell extends Component {
	constructor (props) {
		super(props)
    let tmpValue = this.props.value;
    var _hr = 0;
    var _min = 0;
    var canShow = true;
    console.log("[TBLCELL] - this.props.type = ", this.props.type, this.props.value)
    if(this.props.value === null || this.props.value === undefined) {
      canShow = false;
    }

    if(this.props.type === "numeric" || this.props.type === "dollar")
    {
      tmpValue = parseFloat(tmpValue) || 0;
    } else if(this.props.type === "bool")
    {
      tmpValue = (tmpValue === true ? true : false)
    } else if(this.props.type === "time")
    {
      let theVal = this.props.value || 0;
      var clipSeconds = (theVal%60);
      var finalSeconds = (theVal - clipSeconds)
      let test = moment.duration(finalSeconds, 'seconds');
      tmpValue = test.format("hh:mm")
      _hr = parseFloat(tmpValue.split(':')[0]);
      _min = parseFloat(tmpValue.split(':')[1]);
      console.log("CONSTRUCTOR - [TBLCell]", this.props.type, tmpValue, test, test.hours(), test.asHours(), test.minutes(), test.asMinutes(), '|', finalSeconds, '|', this.props.value)
    }


    //mode: edit/display
		this.state = {
      mode: ((props.editable !== false && props.name !== 'techs') ? ( canShow === false ? 'edit' : 'display' ) : 'display'),
      value: tmpValue,
      time_value: {
        hours: _hr,
        minutes: _min
      },
      type: null
    }
    console.log("[TBLCELL] this.state", this.state)
    this._onChange = this._onChange.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.saveTimeChanges = this.saveTimeChanges.bind(this);
  }


  //Binds the data on entry
  componentDidMount() {
    let tmpValue = this.props.value;
    if(this.props.type === "numeric" || this.props.type === "dollar")
    {
      tmpValue = parseFloat(tmpValue) || 0;
    } else if(this.props.type === "bool")
    {
      tmpValue = (tmpValue === true ? true : false)
    } else if(this.props.type === "time")
    {
      let theVal = this.props.value || 0;
      var clipSeconds = (theVal%60);
      var finalSeconds = (theVal - clipSeconds)
      let test = moment.duration(finalSeconds, 'seconds');
      tmpValue = test.format("hh:mm")
      var _hr = parseFloat(tmpValue.split(':')[0]);
      var _min = parseFloat(tmpValue.split(':')[1]);
      this.setState({
        time_value: {
          hours: _hr || 0,
          minutes: _min || 0
        }
      })
      console.log("[TBLCell]", this.props.type, tmpValue, test, test.hours(), test.asHours(), test.minutes(), test.asMinutes(), '|', finalSeconds, '|', this.props.value)
    }
    this.setState({
      value: tmpValue,
      type: this.props.type
    })

    console.log("Check key ->", this.props.firebaseKey, tmpValue, this.props.type, !isNaN(tmpValue))
    if(this.props.firebaseKey && (this.props.type === "dollar" && !isNaN(tmpValue)))
    {
      this.setState({ 'mode' : 'display' })
    } else if(this.props.firebaseKey && this.props.type === undefined && tmpValue) {
      this.setState({ 'mode' : 'display' })
    }
  }

  //Changes the value for most types (except time)
  _onChange(value) {
    console.log("[TBLCELL] CASE C", value)
    var tmpValue = null;
    if(this.props.type === "bool")
    {
      tmpValue = value.checked
      this.setState({
        'checked': tmpValue
      })
    } else
      tmpValue = value.value;

    if(this.props.type === "numeric" || this.props.type === "dollar") {
      tmpValue = Math.abs(parseFloat(tmpValue))
    }

    return this.setState({
      'value': tmpValue
    })
  }

  saveTimeChanges(time_value) {
    console.log("time_value", time_value)
    var finalSeconds = 0;
    finalSeconds += (time_value.hours * 60 * 60) + ( time_value.minutes * 60 );
    //window.alert(JSON.stringify({ 'time_value': time_value, 'value': finalSeconds}));
    this.setState({ value: finalSeconds, mode: "display" })
    return this.props.updateRow(this.props.name, this.props.fieldIndx, {[this.props.fieldName]: finalSeconds})
  }

  saveChanges(value) {
    console.log("saveChanges(" + JSON.stringify(value) + ")")
    let altValue = null;
    if(value === null || ((this.props.type === "dollar" || this.props.type === "numeric") && isNaN(parseFloat(value))) )
      return;

    if(this.props.type === null && this.props.type !== 'text')
      value = parseFloat(value);

    if(this.props.type === "dollar" || this.props.type === "numeric")
      value = +parseFloat(value).toFixed(2)

    if(this.props.type === "time")
    {
      let subValue = Object.assign(value);
      value = +parseFloat(value);
      altValue = Object.assign({}, value);
      altValue = Math.abs(moment.duration(value, 'hours').asSeconds())
      console.log("fuck you", value,altValue,subValue)
      console.log("saveChanges - [BEFORE] TimeCalc => " + value)
      if(isNaN(altValue))
        altValue = 0;
      console.log("saveChanges - [AFTER] TimeCalc => " + altValue)

      if(subValue === altValue)
        console.log("saveChanges - OK!")
      else
        console.log("saveChanges - Logged incident")

    }

    if(this.props.type !== "time") {
      this.setState({ mode: 'display', value: altValue });
    } else {
      this.setState({ mode: 'display', value: value });
    }

    //Special case: Techs table has multiple fields to bind + modifies multiple jobs' data
    if(this.props.name !== "techs") {
      console.log("fuck", this.props.name, this.props.fieldIndx, altValue, value)
      return this.props.updateRow(this.props.name, this.props.fieldIndx, {[this.props.fieldName]: value})
    } else {
      if(this.props.jid !== null || this.props.jid !== undefined) {
        //console.log(this.props.jid, this.props.name, this.props.fieldIndx, {[this.props.fieldName]: (altValue !== null ? altValue : value )})
        return this.props.updateJobData(this.props.jid, this.props.fieldIndx, {[this.props.fieldName]: (altValue !== null ? altValue : value )})
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let previousValue = prevProps.value || null;
    let newValue = this.props.value || null;
    if (prevProps.value !== this.props.value) {
      console.log('[TBLCELL]', previousValue, newValue)
      if(previousValue === null || previousValue === undefined) {
        //console.log("[TBLCELL] CASE A")
        this.setState({'mode': 'display', 'value': newValue})
        /*if(previousValue != newValue) {
          this.setState({'mode': 'display'})
        }*/
      } else if ( previousValue != null && newValue != null ) {
        //console.log("[TBLCELL] CASE B", this.props.name, this.props.type, newValue)
        this.setState({'mode': 'display', 'value': newValue})
        if(this.props.type === 'time') {
          this.updateTimeEdit( newValue, 'seconds' )
        }
      }
    }
  }

  updateTimeEdit = ( val, period ) => {
    var finalSeconds = Math.abs(moment.duration(val, period).asSeconds());
    let test = moment.duration(finalSeconds, 'seconds');
    let tmpValue = test.format("hh:mm")
    var _hr = parseFloat(tmpValue.split(':')[0]);
    var _min = parseFloat(tmpValue.split(':')[1]);

    console.log("updateTimeEdit", val, finalSeconds, _hr, _min)
    this.setState({'time_value': { 'hours': _hr, 'minutes': _min }})
  }

  calcTime = (time_value) => {
    console.log("saveChanges - calcTime - ", time_value)

    if(time_value == null) {
      time_value = Object.assign({}, {hours: 0, minutes: 0});
    }

    var hours = moment.duration(time_value.hours, 'hours').asSeconds();
    var minutes = moment.duration(time_value.minutes, 'minutes').asSeconds();
    var test = moment.duration((hours + minutes), 'seconds').format('hh:mm')
    return test;
  }

  render() {
    var inputBoxType = "text";
    var displayValue = this.props.value;

    switch(this.props.type) {
      case "image":
        inputBoxType = null;
        break;
      case "bool":
        displayValue = (displayValue === true ? "Yes": "No")
        inputBoxType = "checkbox";
        break;
      case "dollar":
        displayValue = dollarAmountJSX(this.props.value, this.props.editable)
        break;
      case "time":
        var theVal = this.props.value;
        if(theVal === null || theVal === undefined)
        {
          theVal = 0;
        }
        displayValue = timeAmountJSX(theVal)
        break;
      case "date":
        displayValue = moment(displayValue).format(this.props.dateFormat)
        break;
      default:
        inputBoxType = "text";
        break;
    }


    var timeVal = moment.duration(this.props.value, "seconds").asHours();
    //console.log("timeVal", timeVal)
    /*
    var timeValEdited = null;
    try {
      timeValEdited = moment.duration(this.state.value, "hours").asHours();
      if(timeValEdited === 0) {
        timeValEdited = timeVal;
      }
    } catch(e) {
      timeValEdited = timeVal;
    }
    */
    //if(this.props.type === 'time' && this.props.name == 'labor')
    //console.log("saveChanges - timeVal", timeVal, this.state.value, this.props.value, this.props)
    //console.log("timeValEdited", timeValEdited)
    var editModeJSX = {
      'time': (
        <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-evenly'}}>
          <AccountingInput type={this.props.type} tabIndex={this.props.tabIndex} onChange={(value) => { return this.updateTimeEdit( parseFloat(parseFloat(value).toFixed(2)) , 'hours') }} value={timeVal || ''} style={{ input: {'fontSize': '100%'}}}/>
          <div className={'input-action-buttons'} style={{'width': '8em'}}>
            <button onClick={() => { return this.saveTimeChanges(this.state.time_value) }} className="actionableButton saveBtn" alt={'save'}/>
            <button onClick={() => { this.setState({ mode: 'display', value: this.calcTime(this.props.value !== null ? this.props.value : this.state.value) }) }} className="actionableButton cancelBtn" alt={'cancel'}/>
          </div>
        </div>
      ),
      'numeric': (
        <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-evenly'}}>
          <AccountingInput type={this.props.type} tabIndex={this.props.tabIndex} onChange={(value) => { return this.setState({'value': value}) }} value={this.state.value || ''} style={{ input: {'fontSize': '100%'}}}/>
          <div className={'input-action-buttons'} style={{'width': '8em'}}>
            <button onClick={() => { return this.saveChanges(this.state.value) }} className="actionableButton saveBtn" alt={'save'}/>
            <button onClick={() => { this.setState({ mode: 'display', value: this.props.value }) }} className="actionableButton cancelBtn" alt={'cancel'}/>
          </div>
        </div>
      ),
      'normal': (
        <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-evenly'}}>
          <input tabIndex={this.props.tabIndex} style={{'width': ( this.props.type === 'bool' ? '34px' : '100%' ), 'minWidth': ( this.props.type === 'bool' ? '1.5em' : '6em' ), 'fontSize': '100%'}} type={inputBoxType} value={this.state.value || ''} checked={this.state.checked} onChange={(event) => { return this._onChange(event.target) }} />
          <div className={'input-action-buttons'} style={{'width': '8em'}}>
            <button onClick={() => { return this.saveChanges(this.state.value) }} className="actionableButton saveBtn" alt={'save'}/>
            <button onClick={() => { this.setState({ mode: 'display', value: this.props.value }) }} className="actionableButton cancelBtn" alt={'cancel'}/>
          </div>
        </div>
      )
    }

    //We're gonna go ahead and disable the edit function
    //For the old way of inputting billed hours (the 'techs' table)
    //As we've switched from flat rate billing to itemized billing
    var displayModeJSX = (
      <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-evenly'}}>
        <div className={'tbl-cell-print-fix'} style={{fontSize: 'calc(0.75vw + 0.5rem)'}}>{displayValue}</div>
        {this.props.name !== 'techs' ?
          (
            <div className={'input-action-buttons no-select no-print'} style={{'alignSelf': 'flex-end', 'display': ( this.props.editable === false ? 'none': 'inline-block'), width: '3rem', height: '3rem'}}>
              <button onClick={() => { this.setState({ mode: 'edit' }) }} className={this.props.value === null ? "actionableButton editAddBtn" : "actionableButton editBtn"} alt={this.props.value === null ? 'add' : 'edit'}/>
            </div>
          ) : null
        }

      </div>
    );

    switch(this.state.mode) {
      case 'display':
        return displayModeJSX;
      case 'edit':
        if(this.props.type === "numeric" || this.props.type === "dollar")
          return editModeJSX.numeric;
        else if(this.props.type === "time")
          return editModeJSX.time;
        else
          return editModeJSX.normal;
      default:
        return displayModeJSX;
    }
  }
}
