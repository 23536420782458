import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom'
import JobSummary from './components/jobView/index';
import { database, auth, storage } from './datastore/firebase';
import { LoginScreen } from './components/login/index';
import { HomeScreen } from './components/home/index';
import { LogOutScreen } from './components/logout/index';

export default class App extends Component {
	constructor (props) {
		super(props)
		this.state = {
      jid: null,
      app_version: "1.1.0.0",
      currentUser: {},
      redirectToHomePage: false,
      redirectToJobViewPage: false
    }

    this.signOut = this.signOut.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.updateJID = this.updateJID.bind(this);
  }

  componentDidMount() {
    auth.onAuthStateChanged((currentUser) => {
      this.setState({ "currentUser" : currentUser });

      //Do stuff with the current user on start
      if (currentUser) {
        console.log("currentUser", currentUser.uid)
        this.setState({ "currentUser" : currentUser, redirectToHomePage: true })
      }
    });
  }

  signOut() {
    this.setState({redirectToJobViewPage : false, redirectToHomePage: false})
    return auth.signOut();
  }

  updateUser(userObject) {
    return this.setState({ "currentUser" : userObject})
  }

  updateJID(JID) {
    console.log(`updateJID(${JID})`)
    return this.setState({'jid': JID})
  }

  render() {
    var signInRedirectJSX = (<Redirect to={`/login`} push />);
    return (
      <Router>
        <div>
          <nav className={'navigation-bar no-print'}>
            <Link to="/home" onClick={() => { this.setState({jid : null, redirectToJobViewPage: false}) }}>Home</Link>
            <Link to={`/log${this.state.currentUser ? 'off' : 'in'}`} style={{'marginRight': '1em'}} onClick={() => { this.signOut() }}>Log { this.state.currentUser ? 'off' : 'in'}</Link>
            <Route path="/job/:jid?" render={({match}) => {
              return (
								<a href={`/job/${match.params.jid}`} style={{'cursor': 'pointer'}} onClick={() => { localStorage.removeItem('splitPos'); alert("Divider reset, refreshing page"); window.location.reload(true) }}>Reset Divider</a>)
            }} />
          </nav>
          <Switch>
            <Route exact path="/" render={() => {return signInRedirectJSX }} />
            <Route exact path="/login" render={() => <LoginScreen currentUser={this.state.currentUser} redirectToHomePage={this.state.redirectToHomePage} />} />
            <Route exact path="/logout" render={() => <LogOutScreen currentUser={this.state.currentUser} signOut={this.signOut}/>} />
            <Route exact path="/home" render={() => <HomeScreen currentUser={this.state.currentUser} redirectToJobViewPage={this.state.redirectToJobViewPage} /> }/>
            <Route path="/job/:jid?" render={({match}) => {
              if(match.params.jid !== null && this.state.currentUser)
              {
                return (
										<JobSummary
											updateJID={this.updateJID}
											jid={match.params.jid}
											app_version={this.state.app_version}
											storage={storage}
											database={database}
											auth={auth}
											firebase_uid={this.state.currentUser.uid}
										/>
								)
              } else {
                return ( <Redirect to={`/login`} push /> )
              }
            }}
						/>
            <Redirect from='*' to='/home' />
            {signInRedirectJSX}
          </Switch>
        </div>
      </Router>
    );
  }
};
