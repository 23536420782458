import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyAR6z_Y-f9O9ORBcOc9RRYO2VDDjnXfnks",
    authDomain: "jigsaw-production-1.firebaseapp.com",
    databaseURL: "https://jigsaw-production-1.firebaseio.com",
    projectId: "jigsaw-production-1",
    storageBucket: "jigsaw-production-1.appspot.com",
    messagingSenderId: "1072838179851"
}

firebase.initializeApp(config);

export default firebase;

export const database = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const database_value = firebase.database;
