import React, { Component, Fragment } from 'react';
import './index.css';
import { DragSource } from 'react-dnd';
import Viewer from 'react-viewer';
import Dock from 'react-dock';
//import Select from 'react-select';
import PDFViewer from './pdf-viewer/index';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
//import { database_value, database } from '../../../../datastore/firebase';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

const loadingImagePlaceholder = "https://via.placeholder.com/150x150"
const blankPDFDocument = 'https://checkout.lemonde.fr/glm_onestepcheckout/onestep/downloadPdf/'; //Rehost locally!
const imgSource = {
  beginDrag(props) {
    return {...props};
  }
};

function collect(connect, monitor) {
  //connectDragPreview
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

class ImgItem extends Component {
	constructor (props) {
		super(props)
		this.state = {
      imageSrc: (props.isDoc === true ? blankPDFDocument : loadingImagePlaceholder),
      viewerVisible: false,
      isVisible: false,
      isDoc: false,
      defaultPosition: 'bottom',
      currentPosition: 'bottom'
    }
    this.positions = ['bottom', 'left', 'top', 'right'];
    this.positionDropdown = [{value: 'bottom', label: 'bottom'}, {value: 'top', label: 'top'}, {value: 'left', label: 'left'}, {value: 'right', label: 'right'}]
    this.nextPosition = this.nextPosition.bind(this);
    this.prevPosition = this.prevPosition.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.contextTrigger = React.createRef();
  }

  toggleMenu = ( e ) => {
      if(this.contextTrigger) {
          this.contextTrigger.handleContextClick(e);
      }
  };

  prevPosition() {
    var curIndx = this.positions.indexOf(this.state.currentPosition);
    var nxtIndx = curIndx;
    console.log("curIndx", curIndx)

    if(curIndx <= 0)
    {
      nxtIndx = this.positions.length - 1;
      console.log(this.positions.length - 1)
    } else {
      nxtIndx--;
    }

    curIndx = this.positions.indexOf(nxtIndx);
    console.log("nxtIndx", nxtIndx)

    this.setState({
      currentPosition: this.positions[nxtIndx]
    })
  }

  nextPosition() {
    var curIndx = this.positions.indexOf(this.state.currentPosition);
    var nxtIndx = curIndx;
    console.log("nxtIndx", nxtIndx)

    if(curIndx >= 3)
    {
      nxtIndx = 0;
    } else {
      nxtIndx++;
    }

    curIndx = this.positions.indexOf(nxtIndx);
    console.log("nxtIndx", nxtIndx)

    this.setState({
      currentPosition: this.positions[nxtIndx]
    })
  }

  componentDidMount() {
    if(this.props.noFirebase === true && this.props.isDoc !== true)
    {
      this.setState({
        imageSrc: this.props.imageData.src
      })
    } else if( this.props.isDoc === true ) {
      this.setState({
        isDoc: true,
        imageSrc: this.props.imageData.src
      })
    } else {
      return this.props.firebaseStorage.ref("/vwork_photos/").child(`${this.props.imageData.id}.jpeg`).getDownloadURL().then(downloadURL => {
        this.props.updateImageData(this.props.imageData.id, { src: downloadURL })
        return this.setState({
          imageSrc : downloadURL
        })
      })
   }
  }

  updatePosition(newValue) {
    if(newValue === null || newValue === undefined)
      newValue = 'bottom';

    this.setState({
      currentPosition : newValue
    })
  }

  handleClick = (e, data) => {
    console.log("e.target.textContent", e.target.textContent)
    console.log(data);

    var expenseObj = {
      amount: 0.0,
      brh_paid: {
        label: "YES",
        value: "YES"
      },
      document: {
        ...data
      },
      document_required: {
        label: "YES",
        value: "YES"
      },
      home_depot_charge: {
        label: "YES",
        value: "YES"
      },
      type: {
        label: "Materials",
        value: "materials"
      }
    }

    switch( e.target.textContent ) {
      case "Add Home Depot Expense":
        //Add HD Receipt
        //(brh_paid YES, home_depot_charge YES, document_required YES)
        expenseObj.brh_paid.label = "YES";
        expenseObj.brh_paid.value = "YES";
        expenseObj.home_depot_charge.label = "YES";
        expenseObj.home_depot_charge.value = "YES";
        expenseObj.type.label = "Materials"
        expenseObj.type.value = "materials"
        this.props.pushRow( "expenses", expenseObj )

        //Optional: Do OCR and eventually AI to find the answer!
        //Ask the user where the markers should go for cropping in gmagick on windows 2012 server
        //Which then trains an AI to find the total in the picture
        break;
      case "Add Contract":
        //Add a contract
        //(brh_paid NO, home_depot_charge NO, document_required YES)
        expenseObj.brh_paid.label = "NO";
        expenseObj.brh_paid.value = "NO";
        expenseObj.home_depot_charge.label = "NO";
        expenseObj.home_depot_charge.value = "NO";
        expenseObj.type.label = "Contract"
        expenseObj.type.value = "contract"
        this.props.pushRow( "expenses", expenseObj )
        this.props.pushEstimatedLabor()
        break;
      case "Add Parking Reimbursement":
        //(brh_paid NO, home_depot_charge NO, document_required YES)
        expenseObj.brh_paid.label = "NO";
        expenseObj.brh_paid.value = "NO";
        expenseObj.home_depot_charge.label = "NO";
        expenseObj.home_depot_charge.value = "NO";
        expenseObj.type.label = "Parking"
        expenseObj.type.value = "parking"
        this.props.pushRow( "expenses", expenseObj )
        this.props.pushRow( "notes", {
            note: "Technician to be reimbursed for parking totaling $",
            creator: {
              label: "",
              value: ""
            }
          })
        break;
      case "Add Material Reimbursement":
        //(brh_paid NO, home_depot_charge NO, document_required YES)
        expenseObj.brh_paid.label = "NO";
        expenseObj.brh_paid.value = "NO";
        expenseObj.home_depot_charge.label = "NO";
        expenseObj.home_depot_charge.value = "NO";
        expenseObj.type.label = "Materials"
        expenseObj.type.value = "materials"
        this.props.pushRow( "expenses", expenseObj )
        this.props.pushRow( "notes", {
            note: "Technician to be reimbursed for materials totaling $",
            creator: {
              label: "",
              value: ""
            }
          })
        break;
      case "Delete Image":
      this.props.findAndRemoveUserImage( data.id.split(".").shift(), data.name )
        break;
      case "Redownload Image":
      //TODO: Impliment server side re-fetch
        break;
      default:
        break;
    }

  }

  render() {
    var imageData = this.props.imageData;
    const { connectDragSource, isDragging } = this.props;

    var oldImageJSX = (
      <div style={{'display': 'inline-block','position': 'relative'}}>
        <ContextMenuTrigger ref={c => this.contextTrigger = c} id={imageData.id + '_' + imageData.name} holdToDisplay={-1}>
            <div onClick={this.toggleMenu} style={{'display': 'inline-flex','position': 'relative', minWidth: '150px', flexDirection: 'row'}}>
              <img id="no-touch-image" className={'no-touch-image photo-preview'} alt={`${imageData.name}`} src={this.state.imageSrc} style={{opacity: isDragging ? 0.5 : 1, margin: 'auto'}}  />
              <p style={{ 'user-select': 'none', '-ms-user-select': 'none',  '-webkit-user-select': 'none', 'backgroundColor': '#353b48dc', 'color': '#f5f6fa', 'position': 'absolute', 'bottom': '0.75em', 'left': '0.75em', margin: '0px'}}>{`${imageData.name !== null ? imageData.name : ''}`}</p>
            </div>
        </ContextMenuTrigger>
        <Viewer
          visible={this.state.visible}
          onClose={() => { this.setState({ visible: false }); } }
          images={[{src: imageData.src, alt: (imageData.name)}]}
        />
        <ContextMenu preventHideOnScroll={true} id={imageData.id + '_' + imageData.name}>
          <MenuItem data={imageData} onClick={() => { this.setState({ visible: !this.state.visible }) } }>
            View Image
          </MenuItem>
          <MenuItem divider />
          <MenuItem data={imageData} onClick={(e) => this.handleClick(e, imageData)}>
            Add Contract
          </MenuItem>
          <MenuItem divider />
          <MenuItem data={imageData} onClick={(e) => this.handleClick(e, imageData)}>
            Add Home Depot Expense
          </MenuItem>
          <MenuItem data={imageData} onClick={(e) => this.handleClick(e, imageData)}>
            Add Parking Reimbursement
          </MenuItem>
          <MenuItem data={imageData} onClick={(e) => this.handleClick(e, imageData)}>
            Add Material Reimbursement
          </MenuItem>
          {
            imageData.source === "user_uploaded" ? (
              <Fragment>
                <MenuItem divider />
                <MenuItem data={imageData} onClick={(e) => this.handleClick(e, imageData)}>
                  Delete Image
                </MenuItem>
              </Fragment>
            ) : null /*(
              imageData.source == "vWork" ? (
                <Fragment>
                <MenuItem divider />
                <MenuItem data={imageData} onClick={(e) => this.handleClick(e, imageData)}>
                  Redownload Image
                </MenuItem>
                </Fragment>
              ) : null
            )*/
          }
        </ContextMenu>
      </div>
    );

    /*var imageJSX = (
      <div style={{'display': 'inline-block','position': 'relative'}}>
        <div style={{'display': 'inline-block','position': 'relative'}}>
          <img className={'photo-preview'} alt={`${imageData.name}`} src={this.state.imageSrc} style={{opacity: isDragging ? 0.5 : 1}} onClick={() => { this.setState({ isVisible: !this.state.isVisible, viewerVisible: !this.state.viewerVisible }) } } />
          <p style={{'backgroundColor': '#353b48dc', 'color': '#f5f6fa', 'position': 'absolute', 'bottom': '-0.25em', 'left': '0.5em'}}>{`${imageData.name !== null ? imageData.name : ''}`}</p>
        </div>
        <Dock dimMode='none' position={this.state.defaultPosition === this.state.currentPosition ? this.state.defaultPosition : this.state.currentPosition} isVisible={this.state.isVisible}>
            <div className={'dock-controls'}>
              <div onClick={() => this.nextPosition()}>Prev</div>
              <div onClick={() => this.prevPosition()}>Next</div>
              <div style={{ 'display': 'flex', 'flexDirection': 'row' }}>
                <p style={{ 'display': 'inline-block' }}>Select Position</p>
                <Select
                  id={"position_select_" + this.props.imageData.id}
                  simpleValue
                  options={this.positionDropdown}
                  value={this.state.currentPosition}
                  onChange={this.updatePosition}
                  style={{ 'display': 'inline-block' }}
                />
              </div>
            </div>
            <Viewer
              visible={this.state.viewerVisible}
              onClose={() => { this.setState({ viewerVisible: false, isVisible: false }); } }
              images={[{src: imageData.src, alt: (imageData.name)}]}
              container={this.photoDockContainer !== null ? this.photoDockContainer : null}
            />
            <img className={'full-page-image-preview'} src={imageData.src} alt={imageData.name} />
            <div ref={ref => { this.photoDockContainer = ref }} style={{height: '100vh', width: '100%'}}></div>
        </Dock>
      </div>
    );*/

    var docJSX = (
      <div style={{'display': 'inline-block','position': 'relative'}}>
        <div style={{'display': 'inline-block','position': 'relative'}}>
          <img draggable="false" className={'photo-preview'} alt={`${imageData.id}`} src={'/img/pdf-icon.jpg'} style={{opacity: isDragging ? 0.5 : 1}} onClick={() => { this.setState({ isVisible: !this.state.isVisible, viewerVisible: !this.state.viewerVisible }) } } />
          <p style={{'backgroundColor': '#353b48dc', 'color': '#f5f6fa', 'position': 'absolute', 'bottom': '-0.25em', 'left': '0.5em'}}>{`${imageData.name !== null ? imageData.name : ''}`}</p>
        </div>
        <Dock dimMode='none' position={this.state.defaultPosition === this.state.currentPosition ? this.state.defaultPosition : this.state.currentPosition} isVisible={this.state.isVisible}>
            <div className={'dock-controls'}>
              <div onClick={() => { this.setState({ isVisible: !this.state.isVisible, viewerVisible: !this.state.viewerVisible }) } }>
                <p style={{'margin': '0px'}}>X</p>
              </div>
              <div onClick={() => this.nextPosition()}>
                <p style={{'margin': '0px'}}>Prev</p>
              </div>
              <div onClick={() => this.prevPosition()}>
                <p style={{'margin': '0px'}}>Next</p>
              </div>
              <div style={{ 'display': 'flex', 'flexDirection': 'row' }}>
                <p style={{ 'display': 'inline-block', 'margin': '0px' }}>Select Position</p>
                {/*<Select
                  id={"position_select_" + this.props.imageData.id}
                  simpleValue
                  options={this.positionDropdown}
                  value={this.state.currentPosition}
                  onChange={this.updatePosition}
                  style={{ 'display': 'inline-block' }}
                />*/}
              </div>
            </div>
            <div>
              <PDFViewer fileUrl={this.state.imageSrc} fileName={imageData.name} />
            </div>
        </Dock>
      </div>
    );

    return this.state.isDoc === true ? connectDragSource(docJSX, { dropEffect: 'copy' }) : connectDragSource(oldImageJSX, { dropEffect: 'copy' });
  }
}

export default DragSource('img-item', imgSource, collect)(ImgItem);

/*
<div style={{'display': 'inline-block','position': 'relative'}}>
  <img alt={`photo_${i}`} key={`photo_${i}`} src="https://via.placeholder.com/150x150" />
  <p style={{'position': 'absolute', 'bottom': '0', 'right': '1em'}}>{`photo_${i}`}</p>
</div>


<Tooltip placement="top" trigger={['hover']} overlay={() => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <p style={{fontSize: '1.25rem', fontWeight: 600, width: '100%', margin: '0px', textAlign: 'center'}}>Right click me!</p>
    <p style={{fontSize: '1.25rem', width: '100%', margin: '0px', textAlign: 'center'}}>OR</p>
    <p style={{fontSize: '1.25rem', fontWeight: 600, width: '100%', margin: '0px', textAlign: 'center'}}>Touch and hold with your finger!</p>
  </div>
)}>
</Tooltip>
*/
