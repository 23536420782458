import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './index.css';
import FancyTable from '../job-fancy-table/index';
import request from 'request-promise';
import { database } from '../../../datastore/firebase'; //database_value,
import Promise from 'bluebird';

const KEY_CODES = Object.freeze({
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
  UP: 38,
  DOWN: 40,
});

export default class SimpleMap extends Component {
  constructor () {
    super();
    this.state = {
      showModal: false,
      data: null
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  fetchReceipts = () => {
    return request.get({ url: "https://api-v2.brhapps.com/v1/ctool/hd_purchases/list", json: true })
      .then( body => {
        console.log("fetchReceipts()", body.data)
        this.setState({ data: body.data })
        return body.data;
      })
  }

  componentDidMount() {
    return this.fetchReceipts()
  }

  handleOpenModal () {
    this.setState({ showModal: true });
  }

  handleCloseModal () {
    this.setState({ showModal: false });
  }

  saveHDReceipt = (rowObj, i) => {
    console.log("saveHDReceipt", rowObj, i)
    var order_number = rowObj.order_number;
    var receipt_number = rowObj.receipt_number;
    return this.linkReceiptsToJobFile( order_number, receipt_number )
      .then( () => {
        return this.handleCloseModal()
      })
  }

  linkReceiptsToJobFile = ( order_number, receipt_number ) => {
    var smallReceipt = this.props.firebaseStorage.ref(`home_depot_receipts/`).child(`${receipt_number}/${receipt_number}.png`)
    var bigReceipt = this.props.firebaseStorage.ref(`home_depot_receipts/`).child(`${receipt_number}/${order_number}.png`)
    var allPromise = [
      Promise.try(() => {
        return smallReceipt.getDownloadURL()
          .then( url => {
            var dbKey = database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/`).push().key;
            return database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/${dbKey}`).set({
              'id': `${dbKey}.png`,
              'name': `${receipt_number}.png`,
              'source': 'user_uploaded',
              'src': url
            })
          })
        }),
        Promise.try(() => {
          return bigReceipt.getDownloadURL()
            .then( url => {
              var dbKey = database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/`).push().key;
              return database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/${dbKey}`).set({
                'id': `${dbKey}.png`,
                'name': `${order_number}.png`,
                'source': 'user_uploaded',
                'src': url
              })
            })
        })
    ]

    return Promise.each(allPromise)
      .catch(err => console.log("Err", err))

    /*
    console.log("[JobSummaryBody] handleUploadSuccess", this.uploaderValue)
    this.props.firebaseStorage.ref(`/home_depot_receipts/${this.props.jid}`).child(fileName).getDownloadURL().then(url => {
      console.log(fileName, "Download", url)
      var dbKey = database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/`).push().key;

      return database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/${dbKey}`).set({
        'id': fileName,
        'name': database_value.ServerValue.TIMESTAMP,
        'source': 'user_uploaded',
        'src': url
      }).then(() => {
        this.uploaderValue = null;
        console.log("nullified")
      })
    });
    */
  }

  buttonHandleKeyEvents = (event) => {
    switch (event.keyCode) {
      case KEY_CODES.SPACE:
      case KEY_CODES.RETURN:
        event.preventDefault();
        this.handleOpenModal()
        break;
      default:
        break;
    }
  }

  render() {
    var customAddButton = ( rowObj, i ) => (
      <button
        className={'actionableButton addBtn'}
        style={{'margin': '0px', 'boxShadow': 'unset', 'alignSelf': 'center', 'marginLeft': '1em'}}
        onClick={() => this.saveHDReceipt( rowObj, i )}
      />
    );
    return (
      <div>
    		<div role="button" tabindex="0" aria-pressed="false" onKeyDown={this.buttonHandleKeyEvents} onClick={this.handleOpenModal} className='job-actions-tile no-select'>
    		  <img alt={'receipt import button'} src={"../img/receipt-add.png"} />
    		  <p>Import Receipt</p>
    		</div>
          <ReactModal
           isOpen={this.state.showModal}
           contentLabel="Import a receipt modal"
		       ariaHideApp={false}
          >
            <button className={'job-google-map-modal-btn'} onClick={this.handleCloseModal}></button>
            <div className={'job-google-map-container'}>
            <h1 style={{margin: '0px'}}>Select a receipt to import</h1>
            <div style={{ padding: '1rem', border: '2px solid black' }}>
              <h2>Home Depot Receipts</h2>
        			<FancyTable name={'hd_receipts'} rowData={this.state.data} headerData={[
                  {name: 'Date', field: 'created_at', editable: false, type: 'date', dateFormat: 'MM/DD/YYYY'},
                  {name: "Job Name", field: "job_name", editable: false, type: "text"},
                  {name: "Invoice #", field: "invoice_number", editable: false, type: "text"},
                  {name: "Receipt #", field: "receipt_number", editable: false, type: "text"},
                  {name: "Order #", field: "order_number", editable: false, type: "text"},
                  {name: "Store", field: "order_origin", editable: false, type: "text"},
                  {name: "Total", field: "total", editable: false, type: 'dollar'}
                ]}
                deleteRow={() => null}
                updateRow={() => null}
                customButton={customAddButton}
              />
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
