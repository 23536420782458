import React, { Component } from 'react';
import './index.css';
import Viewer from 'react-viewer';
import { DropTarget } from 'react-dnd';

const imgTarget = {
  canDrop(props) {
    return true;
    //return props.imageData.src === null ? true : false; //Should we allow overriding?
  },
  drop(props, monitor, component) {
    //props = current this.props
    //monitor = monitor.getItem() for dropped item
    var imageData = monitor.getItem();
    component.updateImage(imageData)
    console.log("Can't really trust nobody...", imageData)
    return imageData;
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}

class ImgCell extends Component {
	constructor (props) {
		super(props)
		this.state = {
      visible: false
    }
    this.updateImage = this.updateImage.bind(this);
  }

  updateImage(newImageData) {
    console.log(`[ImgCell][${this.props.name}][${this.props.fieldIndx}] = ${JSON.stringify(newImageData.imageData)}`)
    var imageData = {'document': newImageData.imageData};
    return this.props.updateRow(this.props.name, this.props.fieldIndx, imageData)
  }

  render() {
    const { connectDropTarget, isOver, canDrop, imageData} = this.props;
    var borderStyle = 'unset';

    if(isOver && !canDrop)
      borderStyle = '5px solid #e84118';
    else if (isOver && canDrop)
      borderStyle = '5px solid #00a8ff';
    else if (!isOver && canDrop)
      borderStyle = '5px solid #4cd137';

    return connectDropTarget(
      <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'center'}}>
        <div className={'image-field ' + (imageData.src !== null ? '' : 'no-print')} style={{'border': borderStyle, 'marginBottom': (imageData.src !== null ? '1em' : '0'), 'height': (imageData.src !== null ? '72px' : '64px')}}>
          <img id="no-touch-image" className={'thumbnail'} draggable="false" alt="thumbnail" src={imageData.src || '/img/outline.png'} onClick={() => { this.setState({ visible: !this.state.visible }) } }/>
          <p className={'no-select'} style={{ 'whiteSpace': 'pre', 'color': 'black', 'position': 'absolute', 'bottom': '-1em', 'margin': '0px'}}>{`${imageData.name !== null ? imageData.name : ''}`}</p>
          <button style={{ 'display': ( imageData.src === null ? 'none' : 'block')}} onClick={() => { this.updateImage({'imageData': {'name': null, 'src': null}}) }} className="actionableButton deleteItemBtn" alt={'delete'}/>
        </div>
        <Viewer
          visible={this.state.visible}
          onClose={() => { this.setState({ visible: false }); } }
          images={[{src: imageData.src, alt: (imageData.name)}]}
        />
      </div>
    );
  }
}

export default DropTarget('img-item', imgTarget, collect)(ImgCell);
