import React, { Component } from 'react';
import FancyTable from '../job-fancy-table/index';
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';
import './index.css';
import ImgItem from '../job-fancy-table/ImgItem/index';
import { headers } from '../../../constants/tableValues';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { database_value, database } from '../../../datastore/firebase';
import moment from 'moment';
import Promise from 'bluebird';

export default class JobSummaryBody extends Component {
	constructor (props) {
		super(props)
		this.state = {
      dropdowns: {
        expenseTypes: [{value: 'materials', label: 'Materials'}, {value: 'parking', label: 'Parking'}, {value: 'refuse', label: 'Refuse'}, {value: 'equipment', label: 'Equipment'}, {value: 'other', label: 'Other'}, {value: 'tip', label: 'Tip'}, {value: 'contract', label: 'Contract'}, {value: 'goodwill', label: 'Goodwill'}, {value: 'liability', label: 'Liability'}],
        techList: [],
        docked: [{ value: 'NO', 'label': 'NO' }, { value: 'YES', 'label': 'YES' }, { value: 'NP', 'label': 'NP' }, { value: 'PAID', 'label': 'PAID' }],
        creatorList: [],
        YNOptions: [{ value: 'YES', 'label': 'YES' }, { value: 'NO', 'label': 'NO' }]
      },
      visible: false,
      ready: false,
      photos: [],
      techs: {
        itemData: [],
        headerData: []
      },
      billedHours: props.billedHours, //Probably un-needed, maybe not, still learning
      jobRateOverride: props.jobRateOverride, //Probably un-needed, maybe not, still learning
      progress: null,
      isUploading: false,
      userPhotos: [],
      userDocuments: [],
			displayImages: {}
    }

    this.updateImageData = this.updateImageData.bind(this);
    this.handleBtnKeyPress = this.handleBtnKeyPress.bind(this);
    this.updateJobDataLocally = this.updateJobDataLocally.bind(this);
    this.eyesOnMe = this.eyesOnMe.bind(this);
    this.firebaseStorageHandlers = this.firebaseStorageHandlers.bind(this);
    this.updateDocumentData = this.updateDocumentData.bind(this);
    this.uploaderValue = null;
  }

  handleUploadStart = (file, uploadTask) => {
    console.log("[JobSummaryBody] handleUploadStart", this.uploaderValue)
    this.setState({isUploading: true, progress: 0})
  };
  handleProgress = (progress) => this.setState({progress});
  handleUploadError = (error) => {
    this.setState({isUploading: false});
    console.error(error);
  }

  handleUploadSuccess = (fileName) => {
    console.log("[JobSummaryBody] handleUploadSuccess", this.uploaderValue)
    this.setState({progress: 100, isUploading: false});
    this.props.firebaseStorage.ref(`/correction_tool_app/jobs/${this.props.jid}`).child(fileName).getDownloadURL().then(url => {
      this.setState({
        progress: null,
        isUploading: false
      })
      console.log(fileName, "Download", url)
      var dbKey = database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/`).push().key;

      return database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/${dbKey}`).set({
        'id': fileName,
        'name': database_value.ServerValue.TIMESTAMP,
        'source': 'user_uploaded',
        'src': url
      }).then(() => {
        this.uploaderValue = null;
        console.log("nullified")
      })
    });
  };


  shouldComponentUpdate(nextProps, nextState) {
    console.log("[JobSummaryBody] nextProps", nextProps.jid, this.props.jid)
    if(nextProps.jid !== this.props.jid)
    {
      console.log("[JobSummaryBody] Refreshing...")
      return window.location.reload(true)
    } else return true;
/*
    if(nextState.uploadedFileURL !== this.state.uploadedFileURL && nextState.uploadedFileURL !== null)
    {
      console.log("Clearing...")
      this.setState({
        uploadedFileURL: null
      })
      return true;
    }*/
  }

  eyesOnMe( itemRef ) {
    return itemRef.focus();
  }

  firebaseStorageHandlers() {
    database.ref(`/correction_tool_app/images/${this.props.jid}/user_uploaded/`).on('value', (snapshot) => {
      var userUploadedPhotos = snapshot.val();
			var newPhotos = [];
			var newDocumentsArray = [];

			console.log("[JobSummaryBody] userUploadedPhotos", userUploadedPhotos)
      if(!userUploadedPhotos) {
				return this.setState({ userPhotos: newPhotos, userDocuments: newDocumentsArray });
			}

      var userPhotosArray = Object.keys(userUploadedPhotos)

      var fileExtREGEXAlt = /\.[0-9a-z]+$/i;
      var fileExtREGEX = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi;

      for(let i = 0; i < userPhotosArray.length; i++) {
        let photoItem = userUploadedPhotos[userPhotosArray[i]];
        console.log('[JobSummaryBody][userPhotos][' + i + ']', photoItem)
        let fileName = photoItem.id || '';
        var newFileExt = fileName.match(fileExtREGEX) || fileName.match(fileExtREGEXAlt);
        var fileExt = null;
        try {
          console.log("[JobSummaryBody] TRY -> " + newFileExt)
          fileExt = newFileExt[0]
          console.log("[JobSummaryBody] SUCCEED? -> " + fileExt)
        } catch(e) {
          console.log("[JobSummaryBody] MAIN FAILED! -> " + fileName.match(fileExtREGEX))
          console.log("[JobSummaryBody] ALT FAILED! -> " + fileName.match(fileName.match(fileExtREGEXAlt)))
          fileExt = 'unknown';
        }

        if( fileExt === 'pdf' || fileExt === '.pdf' || fileExt === 'unknown')
        {
          newDocumentsArray.push( photoItem );
        }
        else {
          newPhotos.push( photoItem );
        }
      }

      this.setState({ userPhotos: newPhotos, userDocuments: newDocumentsArray })
    })
  }

  //TODO: Unmount all listeners!
  componentWillUnmount() {
    console.log("[JobSummaryBody] componentWillUnmount()")
  }

  componentDidMount() {
    console.log("[JobSummaryBody] componentDidMount()")
    var allPromises = [];
    var tmp = this.state.dropdowns;
    var techList = this.props.mongo.getTechList().then( newList => {
      console.log("[JobSummaryBody] newList.success", newList.success, newList.technicianCodes)
      return newList.technicianCodes;
    }).then(technicianCodes => {
      return Promise.all(technicianCodes.map((techObj, i) => {
        let fullName = techObj.first_name + ' ' + techObj.last_name;
        let formattedName = techObj.code + ' - ' + fullName;
        return {'label': formattedName, 'value': techObj.code};
      })).then(newArr => {
        tmp.techList = newArr;
        return newArr;
      })
    })
    allPromises.push(techList);

    var creatorList = this.props.mongo.getCreatorList();
    var creatorArr = Promise.all(creatorList.map((creatorObj, i) => {
      let fullName = creatorObj.first_name + ' ' + creatorObj.last_name;
      return {'label': fullName, 'value': fullName};
    })).then(creatorArr => {
      tmp.creatorList = creatorArr
      return creatorArr;
    })
    allPromises.push(creatorArr);

    var firebaseStorageHandler = this.firebaseStorageHandlers();
    allPromises.push(firebaseStorageHandler);

    return Promise.all(allPromises)
      .then(() => {
				console.log("Promise.all(allPromises) => ", this.props.jid, this.props.mongo)
        return this.props.mongo.getAllData( this.props.jid )
      })
      .then( allData => {
        var vWorkData = allData.vWorkData;
        console.log("[JobSummaryBody] DATASTORE => ", vWorkData.technicians)
        var techItemData = [];
        //For every tech
        for(let i = 0; i < vWorkData.technicians.length; i++)
        {
          let data = vWorkData.technicians[i];
          let jobData = data.jobs;
          //For every tech's job
          for(let j = 0; j < jobData.length; j++)
          {
            //TODO: Pull hours from flowboard!
            let dataObj = {'id': jobData[j].id, 'id_string': jobData[j].id + ' (' + jobData[j].tpid + ')', 'tech': data.name, 'date': jobData[j].scheduled.start, 'estimated': jobData[j].scheduled.duration, 'billed': 0, actual: jobData[j].actual.duration, rate: 69.00, charge: 0}
            techItemData.push(dataObj);
          }
        }

        var techs = {
          itemData: techItemData,
          headerData: headers.techs || []
        };

        var photoArray = [];
				var dImg = {}

        for(let i = 0; i < vWorkData.photos.length; i++)
        {
          let photoObj = vWorkData.photos[i];
					let vworkPhotoObj = {id: photoObj.fieldId, jobId: (photoObj.jobId || null), name: photoObj.fieldName, jid: photoObj.jobId, jid_dbId: photoObj.jobObjectId, src: null, source: 'vWork'};
          photoArray.push(vworkPhotoObj)
					//dImg[ vworkPhotoObj.jobId ] = false;
					//TODO: Data saving mode toggle triggers this to be false to all
					if(dImg[ vworkPhotoObj.jobId ] == null) {
						dImg[ vworkPhotoObj.jobId ] = {}
					}

					dImg[ vworkPhotoObj.jobId ][ vworkPhotoObj.id ] = false;

/*
					if(vworkPhotoObj.jobId.toString() === this.props.jid) {
						dImg[ vworkPhotoObj.jobId ] = true;
					} else {
						dImg[ vworkPhotoObj.jobId ] = false;
					}
*/

        }
        console.log("[JobSummaryBody] Malone", tmp, photoArray, techs)
        setTimeout(function(){ return this.props.toggleReady(true) }.bind(this), 1600)
        return this.setState({
          "dropdowns": tmp,
          "photos": photoArray,
          "techs": techs,
					"displayImages": dImg
        })
      })
      .catch(err => {
        console.error("FATAL ERROR", err)
      })
  }

	fetchTechCodes = () => {
		return this.props.mongo.getTechList()
		.then( newList => {
      console.log("[JobSummaryBody] fetchTechCodes()", newList.success, newList.technicianCodes)
      return newList.technicianCodes;
    })
		.then( technicianCodes => {
			return Promise.all(technicianCodes.map((techObj, i) => {
				let fullName = techObj.first_name + ' ' + techObj.last_name;
				let formattedName = techObj.code + ' - ' + fullName;
				return { 'name': formattedName, 'code': techObj.code, vworkId: techObj.vworkId };
			}))
		})
	}

	fetchTechByWorkerId = ( jobId ) => {
		//var appVersion = this.props.app_version;
		//if(appVersion >= "1.1.0.0") {
			console.log("[JobSummaryBody] fetchTechByWorkerId(" + jobId +  ")")
			return this.fetchTechCodes()
				.then( techCodes => {
					var techCodeIndex = techCodes.map( x => x.vworkId )
					return this.props.mongo.getvWorkJobData(jobId)
						.then( data => {
							var jobData = data.jobData;
							console.log("jobData", jobData);
							return jobData.worker_id;
						})
						.then( workerId => {
							var finalIndex = techCodeIndex.indexOf( workerId );
							var finalVal = techCodes[ finalIndex ];
							return { label: finalVal.name, value: finalVal.code };
						})
						.catch(err => {
							console.error(err)
							return { label: "", value: "" };
						})
				})
				.catch(err => {
					console.error(err)
					return {label: "", value: ""}
				})
		//}
	}

	pushEstimatedLabor = () => {
		console.log("TODO: pushEstimatedLabor()", this.props.jid);
		return this.fetchTechByWorkerId( this.props.jid )
			.then( techObj => {
				var laborObj = {
						billed: null,
						charge: null,
						job_id: this.props.jid,
						rate: null,
						tech: techObj,
						firebaseKey: null
				};

				return this.props.pushRow( "labor", laborObj)
			})
			.catch(err => {
				console.error(err)
				return null;
			})
	}

	toggleAllDisplayImages = ( jobId ) => {
		var dImgObj = this.state.displayImages;
		var dKeys = Object.values(this.state.photos).filter(x => x.jobId === parseInt(jobId , 10))//Object.keys(dImgObj[jobId]);
		console.log("dKeys", dKeys)
		/*
		if(dImgObj[jobId][photoId] == null) {
			dImgObj[jobId][photoId] = false;
		}
		dImgObj[jobId][photoId] = !dImgObj[jobId][photoId];
		*/
		return Promise.all(dKeys.map( (photoObj, photoIndex) => {
			return Promise.delay((200 * ( photoIndex + 1 )))
				.then( () => {
					dImgObj[ jobId ][ photoObj.id ] = true
					this.setState({ displayImages: dImgObj })
				})
		}))
		.then(() => {
			this.setState({ displayImages: dImgObj })
		})
	}

	toggleDisplayImages = (jobId, photoId) => {
		var dImgObj = this.state.displayImages;
		if(dImgObj[jobId][photoId] == null) {
			dImgObj[jobId][photoId] = false;
		}
		dImgObj[jobId][photoId] = !dImgObj[jobId][photoId];
		this.setState({ displayImages: dImgObj })
	}

  updateImageData(imageId, newImageData) {
    console.log("[JobSummaryBody] updateImageData", imageId, newImageData, this.state.photos);
    var curPhotos = this.state.photos;

    for(let i = 0; i < curPhotos.length; i++)
    {
      if(curPhotos[i].id === imageId)
      {
        var copyImage = Object.assign(curPhotos[i], newImageData);
        curPhotos[i] = copyImage;
        break;
      }
    }

    return this.setState({
      photos: curPhotos
    })
  }

  updateDocumentData(docId, newDocData) {
    console.log(docId, newDocData, this.state.userDocuments);
    var curDocs = this.state.userDocuments;

    for(let i = 0; i < curDocs.length; i++)
    {
      if(curDocs[i].id === docId)
      {
        var copyDoc = Object.assign(curDocs[i], newDocData);
        curDocs[i] = copyDoc;
        break;
      }
    }

    return this.setState({
      userDocuments: curDocs
    })
  }

  handleBtnKeyPress(event, dataField) {
    if(!event.charCode)
      return;

    if(event.charCode === 13 || event.charCode === 32){
      return this.props.newRow( dataField );
    }
  }

  updateJobDataLocally( jid, arrayIndx, data ) {
    let newTechs = this.state.techs;
    console.log("CHANGE", newTechs.itemData[arrayIndx])
    Object.assign(newTechs.itemData[arrayIndx], data)
    this.setState({"techs": newTechs})
    return this.props.updateJobData(jid, data)
  }

	rendervWorkPhotos = () => {
		var finalData = []
		this.state.photos.map((obj, i) => {
			var imageData = {
				'id': obj.id,
				'jobId': obj.jobId,
				'name': obj.name,
				'src': obj.src || null,
				'source': obj.source
			}
			console.log("[JobSummaryBody] New image detected:", imageData)
			return imageData;
		}).sort((a, b) => {
			var itemA = a.jobId;
			var itemB = b.jobId;
			if(itemA > itemB) {
				return 1;
			} else if( itemA < itemB ) {
				return -1;
	} else {
				return 0;
			}
		}).map( imageData => {
			if( finalData[ imageData.jobId ] == null ) {
				finalData[ imageData.jobId ] = []
			}

			return finalData[imageData.jobId].push(imageData)
		})

		return Object.keys(finalData).map( (jobId, indx) => {

				var photoArray = finalData[jobId].map( imageData => {
					console.log("imageData", imageData)
					var isShown = this.state.displayImages[jobId][imageData.id] || false;
					return isShown === true ? (
							<ImgItem pushEstimatedLabor={this.pushEstimatedLabor} findAndRemoveUserImage={this.props.findAndRemoveUserImage} pushRow={this.props.pushRow} key={imageData.id} imageData={imageData} firebaseStorage={this.props.firebaseStorage} updateImageData={this.updateImageData}/>
					) : (
							<div role="button" tabIndex="0" onClick={() => this.toggleDisplayImages(jobId, imageData.id)} style={{ display: 'inline-flex', flexDirection: 'row', cursor: 'pointer', border: '2px solid black', width: '125px', height: '125px', margin: 'auto 1rem'}} className="job-actions-tile">
							  <p style={{margin: 'auto', width: '70%', whiteSpace: 'wrap'}}>{`Download ${imageData.name}`}</p>
							</div>
					)
				});
				var testJSX = (
					<div style={{'margin': `${indx === 0 ? '0em' : '1em' } 0em 0.5em 0em`, 'border-bottom': '2px solid', 'padding': '0em 0em 0em 0.5em', 'background-color': 'rgb(128, 128, 128, 0.2)'}}>
						<div style={{display: 'inline-flex', flexDirection: 'row', width: '100%', 'margin': '0px', 'border-bottom': '2px solid', 'padding': '0.5em 0em 0.5em 0.5em', 'background-color': 'rgb(255, 255, 255, 0.5)', 'font-weight': 'bold'}}>
							<p style={{display: 'inline-block', margin: "auto 1rem"}}>Job #{jobId}</p>
							<p className="job-actions-tile main-action-title" style={{display: 'inline-block', marginLeft: '1rem', cursor: 'pointer', width: 'auto'}} onClick={() => this.toggleAllDisplayImages(jobId)}>Download { finalData[jobId].length } Images</p>
						</div>
						<div style={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap', paddingTop: '1rem', paddingBottom: '1rem' }}>
							{photoArray}
						</div>
					</div>
				);
				return testJSX;
		})
	}

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("[JobSummaryBody] this.props.billedHours", this.props.billedHours, "prevProps.billedHours =", prevProps.billedHours)
    var updateTechTotals = () => {
      console.log("[JobSummaryBody] updateTechTotals()")
      let lastState = prevState.techs;
      let newState = this.state.techs;
      let techData = newState.itemData || [];
      console.log('[JobSummaryBody]', lastState, newState)
      console.log("[JobSummaryBody] this.state.techs", this.state.techs, "prevState.techs =", prevState.techs)
      var headerData = newState.headerData;

      for(let i = 0; i < headerData.length; i++) {
        let data = headerData[i];
        if(data.type === "calculated") {
          var calculation = data.calculation;
          let dataToStore = {[data.field] : 0};
          console.log("[JobSummaryBody] techData", techData)
          for(let k = 0; k < techData.length; k++) {
            switch(calculation.action) {
              case 'multiply':
                var curVal = 1;
                //Process calculation
                for(let j = 0; j < calculation.fields.length; j++) {
                  console.log(`[JobSummaryBody] techData[${k}][${calculation.fields[j]}]`, techData[k][calculation.fields[j]])

                  var field = techData[k][calculation.fields[j]];
                  if(calculation.fields[j] === 'billed') {
                    field = moment.duration(field, 'seconds').asHours()
                  }
                  curVal = parseFloat(curVal * field);
                }

                dataToStore[data.field] = curVal;
                break;
              default:
                break;
            }
            var newData = Object.assign(techData[k], dataToStore);
            techData[k] = newData;
            this.setState({'techs': newState})
            console.log("[JobSummaryBody] COMPLETE!", newState)
          }
        }
      }
    }

		var updateJobRateOverride = () => {
			let newTechs = this.state.techs;
      let techData = newTechs.itemData;
      for(let i = 0; i < techData.length; i++) {
        for(let j = 0; j < this.props.jobRateOverride.length; j++) {
          console.log("[JobSummaryBody] componentDidUpdate this.props.jobRateOverride DBL FOR LOOP",techData[i].id, techData[i], this.props.jobRateOverride[j][techData[i].id])
          if(this.props.jobRateOverride[j][techData[i].id] !== undefined)
          {
            var newJobData = Object.assign(techData[i], {'rate': this.props.jobRateOverride[j][techData[i].id]})
            techData[i] = newJobData;
            this.setState({'techs': newTechs})
            console.log("[JobSummaryBody] componentDidUpdate this.props.jobRateOverride COMPLETE!", newTechs, newJobData)
            updateTechTotals();
          }
        }
      }
		}

    var updateBilledHours = () => {
			console.log("[JobSummaryBody] updateBilledHours()", this.state.techs)
      let newTechs = this.state.techs;
      let techData = newTechs.itemData;
      for(let i = 0; i < techData.length; i++) {
        for(let j = 0; j < this.props.billedHours.length; j++) {
          console.log(techData[i].id, techData[i], this.props.billedHours[j][techData[i].id])
          if(this.props.billedHours[j][techData[i].id] !== undefined)
          {
            var newData = Object.assign(techData[i], {'billed': this.props.billedHours[j][techData[i].id]})
            techData[i] = newData;
            this.setState({'techs': newTechs})
            console.log("[Stupid] COMPLETE!", newTechs)
          }
        }
      }
      return updateJobRateOverride();
    }

    if(this.props.ready !== prevProps.ready)
    {
      console.log("[JobSummaryBody] RDY!", this.props.ready, prevProps.ready)
      updateBilledHours();
    }

    // eslint-disable-next-line
    if(this.state.techs != prevState.techs)
    {
      console.log("[JobSummaryBody] techs changed!")
      updateBilledHours();
    }

    if(this.props.billedHours !== prevProps.billedHours)
    {
      console.log("[JobSummaryBody] billedHours changed!")
      updateBilledHours()
    }

    // eslint-disable-next-line
    if(this.props.jobRateOverride != prevState.jobRateOverride)
    {
			console.log("[JobSummaryBody] jobRateOverride changed!", this.props.jobRateOverride, prevState.jobRateOverride)
      this.setState({ jobRateOverride: this.props.jobRateOverride })
      updateBilledHours()
    }
  }

  render() {
    var couponsJSX = (
      <div className="job-table-container">
        <div className='job-actions-tile small-tile' onClick={() => {this.props.newRow('coupons')}} role="button" onKeyPress={(event) => { return this.handleBtnKeyPress(event, 'coupons')}} aria-pressed="false" aria-label={'coupons'} tabIndex="0"> {/*Actionable*/}
            <img className="no-touch-image" id="no-touch-image" draggable="false" alt="coupon" style={{"marginLeft" : "0.25em"}} src="../img/tag-blue-add.png" />
            <p style={{"marginLeft" : "0.25em"}}>Add</p>
            <p className={'main-action-title'} style={{'fontWeight': '600'}}>Coupon</p>
        </div>
        <div className={'job-print-footer'} style={{'display': (this.props.coupons.itemData.length <= 0 ? 'none': 'block')}}>
          <FancyTable name={'coupons'} rowData={this.props.coupons.itemData} headerData={this.props.coupons.headerData} deleteRow={this.props.deleteRow} updateRow={this.props.updateRow} />
        </div>
      </div>
    );

    var notesJSX = (
      <div className="job-table-container">
        <div className='job-actions-tile small-tile' onClick={() => {this.props.newRow('notes')}} role="button" onKeyPress={(event) => { return this.handleBtnKeyPress(event, 'notes')}} aria-pressed="false" aria-label={'notes'} tabIndex="0"> {/*Actionable*/}
            <img className="no-touch-image" id="no-touch-image" draggable="false" alt="note" style={{"marginLeft" : "0.25em"}} src="../img/note-add.png" />
            <p style={{"marginLeft" : "0.25em"}}>Add</p>
            <p className={'main-action-title'} style={{'fontWeight': '600'}}>Note</p>
        </div>
        <div className={'job-print-footer'} style={{'display': (this.props.notes.itemData.length <= 0 ? 'none': 'block')}}>
          <FancyTable name={'notes'} dropdownData={this.state.dropdowns}  rowData={this.props.notes.itemData} headerData={this.props.notes.headerData} deleteRow={this.props.deleteRow} updateRow={this.props.updateRow} />
        </div>
      </div>
    );

    var expensesJSX = (
      <div className="job-table-container">
        <div className='job-actions-tile small-tile' onClick={() => {this.props.newRow('expenses')}} role="button" onKeyPress={(event) => { return this.handleBtnKeyPress(event, 'expenses')}} aria-pressed="false" aria-label={'expenses'} tabIndex="0"> {/*Actionable*/}
            <img className="no-touch-image" id="no-touch-image" draggable="false" alt="expense" style={{"marginLeft" : "0.25em"}} src="../img/receipt-add.png" />
            <p style={{"marginLeft" : "0.25em"}}>Add</p>
            <p className={'main-action-title'} style={{'fontWeight': '600'}}>Expense</p>
        </div>
        <div className={'job-print-footer'} style={{'display': (this.props.expenses.itemData.length <= 0 ? 'none': 'block')}}>
          <FancyTable name={'expenses'} dropdownData={this.state.dropdowns} rowData={this.props.expenses.itemData} headerData={this.props.expenses.headerData} deleteRow={this.props.deleteRow} updateRow={this.props.updateRow} eyesOnMe={this.eyesOnMe} />
        </div>
      </div>
    );

		var laborJSX = (
      <div className="job-table-container">
        <div className='job-actions-tile small-tile' onClick={() => {this.props.newRow('labor')}} role="button" onKeyPress={(event) => { return this.handleBtnKeyPress(event, 'labor')}} aria-pressed="false" aria-label={'labor'} tabIndex="0"> {/*Actionable*/}
            <img className="no-touch-image" id="no-touch-image" draggable="false" alt="labor" style={{"marginLeft" : "0.25em"}} src="../img/group.png" />
            <p style={{"marginLeft" : "0.25em"}}>Add</p>
            <p className={'main-action-title'} style={{'fontWeight': '600'}}>Labor</p>
        </div>
        <div className={'job-print-footer'} style={{'display': (this.props.labor.itemData.length <= 0 ? 'none': 'block')}}>
          <FancyTable name={'labor'} dropdownData={this.state.dropdowns} rowData={this.props.labor.itemData} headerData={this.props.labor.headerData} deleteRow={this.props.deleteRow} updateRow={this.props.updateRow} eyesOnMe={this.eyesOnMe} />
        </div>
      </div>
    );

    var deductionJSX = (
      <div className="job-table-container">
        <div className='job-actions-tile small-tile' onClick={() => {this.props.newRow('deductions')}} role="button" onKeyPress={(event) => { return this.handleBtnKeyPress(event, 'deductions')}} aria-pressed="false" aria-label={'deductions'} tabIndex="0"> {/*Actionable*/}
            <img className="no-touch-image" id="no-touch-image" draggable="false" alt="deduction" style={{"marginLeft" : "0.25em"}} src="../img/group-delete.png" />
            <p style={{"marginLeft" : "0.25em"}}>Add</p>
            <p className={'main-action-title'} style={{'fontWeight': '600'}}>Deduction</p>
        </div>
        <div className={'job-print-footer'} style={{'display': (this.props.deductions.itemData.length <= 0 ? 'none': 'block')}}>
          <FancyTable name={'deductions'} dropdownData={this.state.dropdowns} rowData={this.props.deductions.itemData} headerData={this.props.deductions.headerData} deleteRow={this.props.deleteRow} updateRow={this.props.updateRow} />
        </div>
      </div>
    );

    var goodwillJSX = (
      <div className="job-table-container">
        <div className='job-actions-tile small-tile' onClick={() => {this.props.newRow('goodwills')}} role="button" onKeyPress={(event) => { return this.handleBtnKeyPress(event, 'goodwills')}} aria-pressed="false" aria-label={'goodwills'} tabIndex="0"> {/*Actionable*/}
            <img className="no-touch-image" id="no-touch-image" draggable="false" alt="goodwill" style={{"marginLeft" : "0.25em"}} src="../img/group-go.png" />
            <p style={{"marginLeft" : "0.25em"}}>Add</p>
            <p className={'main-action-title'} style={{'fontWeight': '600'}}>Goodwill</p>
        </div>
        <div className={'job-print-footer'} style={{'display': (this.props.goodwills.itemData.length <= 0 ? 'none': 'block')}}>
          <FancyTable name={'goodwills'} dropdownData={this.state.dropdowns} rowData={this.props.goodwills.itemData} headerData={this.props.goodwills.headerData} deleteRow={this.props.deleteRow} updateRow={this.props.updateRow} />
        </div>
      </div>
    );

    var techsJSX = (
      <div style={{'marginTop': '1em'}}>
        <div className='job-actions-tile small-tile'>
            <img className="no-touch-image" id="no-touch-image" draggable="false" alt="techs" style={{"marginLeft" : "0.25em"}} src="../img/group.png" />
            <p className={'main-action-title'} style={{'fontWeight': '600'}}>Techs</p>
        </div>
        <div className={'job-print-footer'}>
          <FancyTable name={'techs'} rowData={this.state.techs.itemData} headerData={this.state.techs.headerData} removable={false} updateRow={this.props.updateRow} updateJobData={this.updateJobDataLocally} sortByField={'date'} />
        </div>
      </div>
    );

    return (
      <div className={'job-print-body'}>
          {this.state.techs.headerData !== undefined ? techsJSX : null}
        <div className="job-tables">
					{this.props.labor !== undefined ? laborJSX : null}
          {this.props.coupons !== undefined ? couponsJSX : null}
          {this.props.notes !== undefined ? notesJSX : null}
          {this.props.expenses !== undefined ? expensesJSX : null}
          {this.props.deductions !== undefined ? deductionJSX : null}
          {this.props.goodwills !== undefined ? goodwillJSX : null}
        </div>
        <div style={{'marginTop': '1em'}}>
          <div className='job-actions-tile small-tile' onKeyPress={(event) => { return null; }}> {/*TODO: Actionable - PHOTOS*/}
              <img className="no-touch-image" id="no-touch-image" draggable="false" alt="add" style={{"marginLeft" : "0.25em"}} src="../img/photo-add.png" />
              <p style={{"marginLeft" : "0.25em"}}></p>
              <p className={'main-action-title'} style={{'fontWeight': '600'}}>Photos</p>
          </div>
          <button className="no-print" onClick={() => { this.setState({ visible: !this.state.visible, ready: false }); } } style={{ 'display': (this.state.visible === true ? 'none' : (this.state.photos.length <= 0 ? 'none': 'block') ), 'float': 'right'}}>Show Inline</button>
          <CustomUploadButton
            accept="image/*, application/pdf, application/x-pdf, application/xhtml+xml"
            name="user_photo_uploader"
            randomizeFilename
            storageRef={this.props.firebaseStorage.ref(`/correction_tool_app/jobs/${this.props.jid}`)}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
            value={this.uploaderValue || ''}
            style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4, position: 'relative', top: '-0.75em', right: '7em', 'opacity': 0, zIndex: 0}}
          >
          Upload photos
          </CustomUploadButton>
          <div className={'job-print-footer'}>
			  			{this.rendervWorkPhotos()}
              {(() => {
								var allUserPhotos = this.state.userPhotos.map((obj, i) => {
		                var imageData = {
		                  'id': obj.id,
		                  'name': obj.name,
		                  'src': obj.src || null,
		                  'source': obj.source
		                }
		                console.log("[JobSummaryBody] New image detected:", imageData)
		                return (
											<div style={{marginLeft: '1rem'}}>
		                  	<ImgItem noFirebase={true} pushEstimatedLabor={this.pushEstimatedLabor} findAndRemoveUserImage={this.props.findAndRemoveUserImage} pushRow={this.props.pushRow} key={imageData.id} imageData={imageData} firebaseStorage={this.props.firebaseStorage} updateImageData={this.updateImageData}/>
											</div>
										);
								})
								return (
									<div style={{'margin': `1em 0em 0.5em 0em`, 'border-bottom': '2px solid', 'padding': '0em 0em 0em 0.5em', 'background-color': 'rgb(128, 128, 128, 0.2)'}}>
										<div style={{display: 'inline-flex', flexDirection: 'row', width: '100%', 'margin': '0px', 'border-bottom': '2px solid', 'padding': '0.5em 0em 0.5em 0.5em', 'background-color': 'rgb(255, 255, 255, 0.5)', 'font-weight': 'bold'}}>
											<p style={{display: 'inline-block', margin: "auto 1rem"}}>Uploaded photos</p>
										</div>
										<div style={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap', paddingTop: '1rem', paddingBottom: '1rem' }}>
											{allUserPhotos}
										</div>
									</div>
								);
						})()
						}
              {this.state.userDocuments.map((obj, i) => {
                var docData = {
                  'id': obj.id,
                  'name': obj.name,
                  'src': obj.src || null,
                  'source': obj.source
                }
                console.log("[JobSummaryBody] New document detected:", docData)
                return (
                  <ImgItem isDoc={true} pushEstimatedLabor={this.pushEstimatedLabor} findAndRemoveUserImage={this.props.findAndRemoveUserImage} pushRow={this.props.pushRow} noFirebase={true} key={docData.id} imageData={docData} updateImageData={this.updateDocumentData}/>
                );
              })}
              {this.state.photos.length <= 0 ? (<p>{'No vWork photos found :('}</p>) : null }
          </div>
          <div className={'job-print-footer div-shrink-animation'} ref={ref => {this.container = ref}} style={{'width': '70vw', 'height': (this.state.visible === true ? '100vh' : 'unset'),'animation': (this.state.ready === true ? 'closeOut 1s' : 'unset') }}></div>
            <Viewer
              visible={this.state.visible}
              onClose={() => { this.setState({ visible: false, ready: true }); } }
              images={this.state.photos.map((obj, i) => {
                  return {'src': obj.src, 'alt': obj.name}
              })}
              container={this.container !== null ? this.container : null}
            />
        </div>
      </div>
    );
  }
};
