module.exports = {
  headers : {
    deductions: [{name: 'Tech', field: 'tech', type: 'dropdown', dropdown: { options: 'techList', name: 'tech' }}, {name: 'Explain', field: 'note'}, {name: 'Amount', field: 'amount', type: 'numeric'}],
    goodwills: [{name: 'Tech', field: 'tech', type: 'dropdown', dropdown: { options: 'techList', name: 'tech' }}, {name: 'Docked', field: 'docked', type: 'dropdown', dropdown: { options: 'docked', name: 'reason' }}, {name: 'Hours', field: 'hours', type: 'numeric'}, {name: 'Note', field: 'note', type: 'text'}],
    techs: [{name: 'ID', field: 'id_string', editable: false}, {name : 'Tech', field: 'tech', editable: false}, {name: 'Date', field: 'date', editable: false, type: 'date', dateFormat: 'MM/DD/YYYY @ hh:mm a'}, {name: 'Booked', field: 'estimated', editable: false, 'type': 'time'}, {name: 'Calc.', field: 'actual', type: 'time', editable: false}, {name: 'Billed', field: 'billed', type: 'time'
  }, {name: 'Rate', field: 'rate', type: 'dollar'}, {name: 'Charge', field: 'charge', editable: false, 'type': 'calculated', 'calculation': { 'action': 'multiply', fields: ['rate', 'billed'], type: 'dollar'} }],
    coupons: [{name: 'Code', field: 'coupon_code'}, {name: 'Note', field: 'note'}],
    notes: [{name: 'Creator', field: 'creator', type: 'dropdown', dropdown: {options: 'creatorList', name: 'creator'}}, {name: 'Note', field: 'note'}],
    expenses: [{name: 'Type', field: 'type', type: 'dropdown', dropdown: { options: 'expenseTypes', name: 'type' }},{name: 'Amount', field: 'amount', type: 'dollar'}, {name: 'BRH Paid', field: 'brh_paid', type: 'dropdown', dropdown: { options: 'YNOptions', name: 'answer' }}, {name: 'Home Depot', field: 'home_depot_charge', type: 'dropdown', dropdown: { options: 'YNOptions', name: 'answer' }}, {name: 'Doc. Required', field: 'document_required', type: 'dropdown', dropdown: { options: 'YNOptions', name: 'answer' }}, {name: 'Document', field: 'document', type: 'image'}],
    labor: [{name: 'ID', field: 'job_id', type: 'text'}, {name: 'Tech', field: 'tech', type: 'dropdown', dropdown: { options: 'techList', name: 'tech' }}, {name: 'Billed', field: 'billed', type: 'time'},{name: 'Rate', field: 'rate', type: 'dollar'}, {name: 'Charge', field: 'charge', editable: false, 'type': 'calculated', 'calculation': { 'action': 'multiply', fields: ['rate', 'billed'], type: 'dollar'} } ]
  }
}
