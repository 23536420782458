import React, { Component } from 'react';
import Lottie from 'react-lottie';
import loadingBarAnimData from '../../datastore/animations/progress_bar.json';
import BRHLogo from './logo.png';
import { CSSTransition } from 'react-transition-group';
import './index.css';

export default class LoadingScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isStopped: false,
			isPaused: false
		};

		this.loadingBGColor = 'rgba(108, 122, 224, 1.0)';
	}

	componentWillUnmount() {
		this.setState({
			'isStopped': true,
			'isPaused': true
		})
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: loadingBarAnimData,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice'
			}
		};

		const loaderJSX = (
			<div key="loaderJSX" className={'loadingScreen'} style={{ 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'backgroundColor': this.loadingBGColor, position: 'sticky', 'top': '0px', 'left': '0px', 'zIndex': 1 }}>
				<img src={BRHLogo} alt="budget right handyman logo" style={{ 'display': 'inline-block', 'width': '40%', 'alignSelf': 'center' }} />
				<div style={{ 'display': 'inline-block', 'width': '100vw', 'height': '5em', 'backgroundColor': 'rgba(0, 0, 0, 0)', 'alignSelf': 'center', 'top': '0px', 'left': '0px', 'zIndex': 2 }}>
					<Lottie options={defaultOptions}
						isStopped={this.state.isStopped}
						isPaused={this.state.isPaused}
					/>
				</div>
				<p style={{ 'alignSelf': 'center', 'fontSize': '3em', 'paddingBottom': '1em', 'color': '#ecf0f1' }}>{`Loading Job #${this.props.jid} details...`}</p>
				<div style={{ 'width': '100%', 'height': '100%', position: 'absolute', 'top': '0px', 'left': '0px', 'zIndex': 3 }}></div>
			</div>
		)

		return (
			<CSSTransition
				in={this.props.ready === false}
				timeout={750}
				classNames="loadingScreen"
				unmountOnExit
			>
				{loaderJSX}
			</CSSTransition>
		);
		/*
				<button style={buttonStyle} onClick={() => this.setState({isStopped: true})}>stop</button>
				<button style={buttonStyle} onClick={() => this.setState({isStopped: false})}>play</button>
				<button style={buttonStyle} onClick={() => this.setState({isPaused: !this.state.isPaused})}>pause</button>
		*/
	}
};
